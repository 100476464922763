import firebase from 'firebase';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/storage';
import '@firebase/functions';
import "@firebase//analytics";
import '@firebase/app-check'
import '@firebase/messaging';
// const messaging = firebase.messaging();

import { Platform } from "react-native";
//live
const firebaseConfig = {
    apiKey: process.env.REACT_APP_GOOGLE_API,
    authDomain: "metadoc-app.firebaseapp.com",
    projectId: "metadoc-app",
    storageBucket: "metadoc-app.appspot.com",
    messagingSenderId: "809013643436",
    appId: "1:809013643436:web:a6463fd1dec16aa251f709",
    measurementId: "G-YQTFPK7T87"
};
//test
// const firebaseConfig = {
//     apiKey: "AIzaSyD4HeHWcoIgd9QYqVq3A9wAnhD8tEBMIR4",
//     authDomain: "metadoc-dev.firebaseapp.com",
//     projectId: "metadoc-dev",
//     storageBucket: "metadoc-dev.appspot.com",
//     messagingSenderId: "775059749917",
//     appId: "1:775059749917:web:5cef3e130da4ae419f9af4",
// };
let firestore;
let auth;
let storage;
let messaging;
let functions;
let analytics;


if (!firebase.apps.length) {
    // if (Platform.OS === 'web') {
    firebase.initializeApp(firebaseConfig);
    const appCheck = firebase.appCheck();

    appCheck.activate('6Lem2_8hAAAAAAqiYx4yLywdBW7CdCaP4QBYPp8F', true)
    firestore = firebase.firestore
    functions = firebase.functions

    auth = firebase.auth
    storage = firebase.storage
    messaging = firebase.messaging
    analytics = firebase.analytics

    // }

}
// const appCheck = firebase.appCheck();
// // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// // key is the counterpart to the secret key you set in the Firebase console.

// appCheck.activate('6LcaEEcgAAAAAEZ0anpDF0oFMXmFEAps0rhMJ-dw',
//     // Optional argument. If true, the SDK automatically refreshes App Check
//     // tokens as needed.
//     true);
export { firestore, auth, storage, messaging, functions, analytics };

