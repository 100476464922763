import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { TextInput, TouchableOpacity } from 'react-native'
import ActionBtn from '../../shared/ActionBtn';
import { useNavigation } from '@react-navigation/core';
// import { getLocations, checkLocationsServed } from '../../helpers/helpers.js';
import Alert from '../../shared/Alert';
import CustomImage from '../../shared/customImg';
import { strings } from '../../../translations/index'
import { CurrentLocation, Location } from '../../../constants/imgs';
import Geocode from "react-geocode";
import { View } from 'native-base';
import { CheckLocationIsServed, makeid, shadow, updateOrderForLocationChange } from '../../../helpers/helpers';
import { SetAlertMessage } from '../../../reducers/settings';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { SetUserDefaultLocation } from '../../../reducers/user';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Text from '../../shared/TextLabel';

const NewLocation = ({ route }) => {
    const navigation = useNavigation();
    const { servingAreas, webView } = useSelector((state) => state.settings);
    const { items } = useSelector((state) => state.order);
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user);
    const [state, setState] = useState({
        center: {
            lat: webView ? userData?.defaultLocation?.latitude : 25.1903,
            lng: webView ? userData?.defaultLocation?.longitude : 55.2747,
        },
        addressObject: {},
        locations: {},
        alert: false,
        loading: false
    })

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_API,
        debounce: 1000,
        language: 'en',
        libraries: ['places'],
        options: {
            types: ['establishment'],
            language: 'en',
            componentRestrictions: { country: 'ae' },
            fields: ['place_id', 'name', 'types']
        },

    });

    useEffect(() => {
        if (!webView) {
            getCurrentLocation()
        }
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API);
        Geocode.setRegion("ae");
        Geocode.setLanguage('en')
        Geocode.setLocationType("GEOMETRIC_CENTER");
        window.document.body.style.height = window.innerHeight + 'px'
        window.document.body.style.overflow = 'hidden'
        window.scroll(0, 0)
        return () => {
            window.document.body.style.height = '100vh'
            window.document.body.style.overflow = 'auto'
        }
    }, []);

    const getCurrentLocation = () => {
        navigator?.geolocation?.getCurrentPosition((position) => {
            if (position) {
                setState({ ...state, center: { lat: position?.coords?.latitude, lng: position?.coords?.longitude } })
            }
        });
    }

    const constructAddressObject = (addressObject) => {
        let { formatted_address, geometry, address_components } = addressObject

        let address = address_components?.reduce((value, currentValue) => {
            if (currentValue.types.includes("neighborhood")) {
                value.area = currentValue.short_name
            }
            if (!value.area && currentValue.types.includes("sublocality")) {
                value.area = currentValue.short_name
            }
            if (currentValue.types.includes("route")) {
                value.street = currentValue.short_name
            }
            if (currentValue.types.includes("locality")) {
                value.emirate = currentValue.short_name
            }
            return value
        }, {})

        if (geometry) {
            addressObject.geometry = {
                location: {
                    lat: geometry?.location?.lat,
                    lng: geometry?.location?.lng,
                },
                viewport: {
                    south_west: geometry?.viewport?.southwest,
                    north_East: geometry?.viewport?.northeast
                }
            }

            let center = { lat: geometry.location.lat, lng: geometry.location.lng }
            return ({ center, addressObject, address })
        }
    }

    return (
        <View style={{ flex: 1, alignItems: 'center' }}>
            {
                state?.alert && <Alert
                    alert
                    title={strings.sorry}
                    cancelText='dismiss'
                    msg={strings.notServingArea}
                    onCancel={() => setState({ ...state, alert: false })} />
            }
            <View style={{ position: 'absolute', zIndex: 9, width: '90%', marginVertical: 32 }}>
                <TextInput
                    style={{ textAlign: 'left', outline: 'none', color: '#000', backgroundColor: '#fff', width: '100%', paddingVertical: 16, paddingHorizontal: 12, borderRadius: 8, height: 50, border: 'none' }}
                    placeholder={'Search'}
                    onChange={(evt) => {
                        setState({ ...state, hidePredictions: false })
                        getPlacePredictions({ input: evt.target.value });
                    }}
                    loading={isPlacePredictionsLoading}
                />
                <View paddingTop={4}>
                    {!state?.hidePredictions && placePredictions.map((data) => {
                        let { structured_formatting, reference, place_id } = data
                        let { main_text, secondary_text } = structured_formatting
                        return (
                            <View style={{ width: '100%', overflow: 'hidden', flex: 1, backgroundColor: '#fff', padding: 8, borderBottomColor: '#cacaca', borderBottomWidth: 1 }} key={reference}>
                                <TouchableOpacity onPress={() => {
                                    placesService?.getDetails(
                                        {
                                            placeId: place_id,
                                            fields: ['formatted_address', 'geometry']
                                        },
                                        (placeDetails) => {
                                            let center = { lat: placeDetails?.geometry?.location?.lat(), lng: placeDetails?.geometry?.location?.lng() }
                                            setState({ ...state, center, hidePredictions: true });
                                        }
                                    );

                                }}>
                                    <Text numberOfLines={1} ellipsizeMode='tail' style={{ width: '100%', color: '#000', fontSize: 12, textAlign: 'left' }}>{main_text}</Text>
                                    <Text numberOfLines={1} ellipsizeMode='tail' style={{ color: '#cacaca', fontSize: 12, textAlign: 'left' }}>{secondary_text}</Text>
                                </TouchableOpacity>
                            </View>
                        )

                    })}
                </View>
            </View>
            <div style={{ height: webView ? '100vh' : '100%', width: '100vw' }}>
                <GoogleMapReact
                    style={{ height: '100%', width: '100vw' }}
                    options={
                        { fullscreenControl: false, zoomControl: false }
                    }

                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API, language: 'en', region: 'AE', libraries: ['places'] }}
                    defaultCenter={state.center}
                    center={state.center}
                    yesIWantToUseGoogleMapApiInternals
                    onChange={e => setState({ ...state, center: e?.center })}
                    // onBoundsChange={(e) => this.setState({ center: e })}
                    defaultZoom={16}
                >
                </GoogleMapReact>
            </div>
            <View style={{ position: 'fixed', right: 16, bottom: 120 }}>
                <TouchableOpacity
                    onPress={() => {
                        getCurrentLocation()
                    }}

                    shadow={shadow({ color: '#00000020', radius: 8, offsetWidth: 1, offsetHeight: 1 })}
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50,
                        backgroundColor: '#fff',
                        borderColor: '#fff',
                        borderWidth: 1,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <CustomImage
                        svg
                        fill={'#20A2DF'}
                        width={30}
                        height={30}
                        uri={CurrentLocation}
                    />
                </TouchableOpacity>

            </View>
            <View style={{ position: 'absolute', top: '50%', left: '50%' }}>
                <View style={{ marginLeft: -20, marginTop: -20 }}>
                    <CustomImage
                        svg
                        fill={'#20A2DF'}
                        width={40}
                        height={40}
                        uri={Location}
                    />
                </View>
            </View>
            <ActionBtn
                loading={state.loading}
                borderColor='#20A2DF'
                title={strings?.confirmLocation}
                onPress={() => {
                    let center = state?.center
                    setState({ ...state, loading: true });
                    if (center) {
                        Geocode.fromLatLng(center.lat, center.lng).then(
                            async (response) => {
                                const address = response?.results?.[0];
                                let result = constructAddressObject(address);
                                let { place_id, cluster } = await CheckLocationIsServed(servingAreas, { latitude: center.lat, longitude: center.lng })

                                if (!place_id) {
                                    setState({ ...state, loading: false });
                                    dispatch(SetAlertMessage({
                                        title: 'Sorry',
                                        msg: strings?.['We are not currently serving this area, but we are expanding very fast, check us later!'],
                                        alert: true,
                                    }))
                                    return
                                }
                                window.document.body.style.height = '100vh'
                                window.document.body.style.overflow = 'auto'

                                if ((!userData?.uid || !Object.keys(items || {})?.length) && !route?.params?.addDetails) {
                                    let post = {
                                        id: makeid(),
                                        geometry: result.addressObject?.geometry,
                                        details: result.addressObject,
                                        latitude: center.lat,
                                        longitude: center.lng,
                                        address: result.address
                                    };

                                    dispatch(SetUserDefaultLocation({ ...post, created: moment().valueOf(), place_id, cluster, anonimous: true }))
                                    if (items && Object?.keys(items || {})?.length) {
                                        dispatch(updateOrderForLocationChange({ items, defaultLocation: { ...post, created: moment().valueOf(), place_id, cluster, anonimous: true }, cluster: servingAreas?.find(rec => rec.id === cluster) }));
                                    }
                                    navigation.goBack()
                                    if (route?.params?.action) {
                                        route?.params.action();
                                    }
                                    setState({ ...state, loading: false });
                                    return
                                }

                                navigation.navigate('Address Details', {
                                    selectedRegon: {
                                        place_id,
                                        details: result.addressObject,
                                        latitude: center.lat,
                                        longitude: center.lng,
                                        address: result.address
                                    },
                                })
                                setState({ ...state, loading: false });

                            },
                            (error) => {
                                console.log("error", error);
                                window.document.body.style.height = '100vh'
                                window.document.body.style.overflow = 'auto'
                                navigation.navigate('Address Details', {
                                    selectedRegon: {
                                        details: {
                                            geometry: {
                                                location: {
                                                    center
                                                }
                                            }
                                        },
                                        latitude: center.lat,
                                        longitude: center.lng,
                                    },
                                })
                            }
                        ).catch(error => {
                            setState({ ...state, loading: false });
                            console.log("error", error);
                        });
                        return
                    } else {
                        setState({ ...state, alert: true, loading: false })
                    }
                }
                } />

        </View >
    );


};
export default NewLocation