import { useNavigation } from '@react-navigation/native';
import { View, FlatList, Text, Spinner } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import OrderSummarySheet from '../../shared/OrderSummarySheet';
import Image from '../../shared/customImg';
import { SearchIcon } from '../../../constants/imgs';
import { TouchableOpacity } from 'react-native';
import { SetLoginRequired } from '../../../reducers/settings';
import { GetCategories, GetMedicines } from '../../../reducers/prds';
import moment from 'moment';
import { Localize, useDebounce } from '../../../helpers/helpers';
import Input from '../../shared/CustomInput';
import { strings } from '../../../translations/index';
import LongList from '../../shared/LongList/index';

const Index = ({ route }) => {
    const { serviceID } = route?.params;
    const user = useSelector(state => state.user);
    const { locale } = useSelector(state => state.locale)
    const { variations } = useSelector(state => state.settings);
    const { items } = useSelector(state => state.order);
    const { medicines, categories } = useSelector(state => state.helpers);
    const [state, setState] = useState({ loading: true })
    const dispatch = useDispatch();
    const debouncedSearchTerm = useDebounce(state?.search, 700);
    const { height } = useWindowDimensions()
    useEffect(() => {
        switch (serviceID) {
            case 'pharmacy':
                if (!medicines?.prds?.length || moment(medicines?.created).add(15, 'minutes').isBefore(moment())) {
                    dispatch(GetMedicines())
                }
                if (Platform.OS === 'web') {
                    window.scrollTo(0, 0)
                }
                break;
            default:
                dispatch(GetCategories())

                break;
        }
    }, [serviceID])

    useEffect(() => {
        switch (serviceID) {
            case 'pharmacy':
                if (medicines?.prds) {
                    let initialCategory = medicines?.categories?.[0]
                    let initialPrds = medicines.prds?.filter(r => r?.category_id === initialCategory.id)
                    setState({ ...state, prds: initialPrds, category: initialCategory, categories: medicines?.categories })
                }
                break;
            default:
                let serviceVariations = variations?.filter(r => r.service_id === serviceID);
                let serviceCategories = serviceVariations.reduce((val, currentVal) => {
                    currentVal?.variation_categories?.map(r => {
                        if (!val.includes(r)) {
                            val.push(r)
                        }
                    })
                    return val
                }, [])
                serviceCategories = serviceCategories.reduce((val, r) => {
                    let category = categories.find(rec => rec.id === r)
                    if (category) {
                        val.push({ ...category, name: Localize(category?.name || "") })
                    }
                    return val
                }, [])?.sort((a, b) => a?.index - b?.index)
                let initialCategory = serviceCategories?.[0]
                let initialPrds = serviceVariations?.filter(r => r?.variation_categories?.includes(initialCategory?.id))
                setState({ ...state, prds: initialPrds, serviceVariations, category: initialCategory, categories: serviceCategories, loading: initialCategory ? state.loading : false })
                break;
        }

    }, serviceID === 'pharmacy' ? [medicines, categories, serviceID] : [categories, variations, serviceID])

    useEffect(() => {
        let { category, search, serviceVariations } = state;
        if (!category && !search) {
            return
        }
        let results;
        switch (serviceID) {
            case 'pharmacy':

                if (search) {
                    results = medicines?.prds?.filter(r => r?.name?.toLowerCase().includes(search?.toLowerCase()))
                } else if (category) {
                    results = medicines?.prds?.filter(r => r?.category_id === category.id)
                }
                setState({ ...state, prds: results, loading: false })
                break;
            default:
                if (search) {
                    results = serviceVariations?.filter(r => Localize(r?.name)?.toLowerCase().includes(search?.toLowerCase()))?.sort((a, b) => a?.index - b?.index)
                } else if (category) {
                    results = serviceVariations?.filter(r => r?.variation_categories?.includes(category.id))?.sort((a, b) => a?.index - b?.index)
                }
                setState({ ...state, prds: results, loading: false })
                break;
        }
    }, [debouncedSearchTerm, state?.category, state?.serviceVariations])

    const navigation = useNavigation()

    return (
        <View flex={1} minHeight={Platform.OS === 'web' ? height : '80%'} >
            <View
                backgroundColor='#FFFFFF'>

                <View
                    marginX={4}
                    backgroundColor='#FFFFFF'
                    borderRadius={12}
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    borderColor={'#E5E5E5'}
                    borderWidth={1}
                    paddingX={4}
                    marginBottom={4}
                    height={50}
                >
                    <View>
                        <Image
                            uri={SearchIcon}
                            width={16}
                            height={16}
                            svg
                            fill='#0F172A'
                        />
                    </View>

                    <Input
                        style={{
                            marginHorizontal: 6,

                        }}
                        textAlign={locale === 'ar' ? 'right' : 'left'}
                        placeholder={strings?.['Search']}
                        fontSize={16}
                        defaultValue={debouncedSearchTerm}
                        height={'auto'}
                        margin={0}
                        padding={0}
                        onChangeText={(text) => {
                            setState({ ...state, loading: true, search: text })
                        }}
                        placeholderTextColor={'#cacaca'}
                        borderWidth={0}
                    />
                </View>
                {
                    state?.categories ?
                        <View  >

                            <FlatList
                                keyboardDismissMode='on-drag'
                                showsHorizontalScrollIndicator={false}
                                horizontal
                                contentContainerStyle={{
                                    paddingHorizontal: 16
                                }}
                                ItemSeparatorComponent={() => <View padding={1} />}
                                renderItem={({ item }) => <TouchableOpacity
                                    onPress={() => {
                                        setState({ ...state, category: item, loading: true })
                                    }}
                                    style={{
                                        marginBottom: 10,
                                        paddingHorizontal: 12,
                                        paddingVertical: 8,
                                        borderRadius: 8,
                                        backgroundColor: '#fff',
                                        borderWidth: 1,
                                        borderColor: state?.category?.id === item?.id ? '#20A2DF' : '#E5E5E5',
                                        backgroundColor: state?.category?.id === item?.id ? '#20A2DF' : '#fff'
                                    }}
                                    key={item?.id} >
                                    <Text style={{ color: state?.category?.id === item?.id ? '#fff' : '#64748B' }}>
                                        {item?.name}
                                    </Text>
                                </TouchableOpacity>}
                                data={state?.categories}
                            />
                        </View>
                        :
                        <View />
                }
            </View>
            {
                state?.loading ?
                    <View flex={1} justifyContent='center' alignItems={'center'}>
                        <Spinner />
                    </View>
                    :
                    <LongList allItems={state?.prds} serviceID={serviceID} navigation={navigation} />

                // <FlatList
                //     keyboardDismissMode='on-drag'
                //     horizontal={false}
                //     data={state?.prds}
                //     renderItem={renderItem}
                //     keyExtractor={keyExtractor}
                //     showsVerticalScrollIndicator={false}
                //     ListEmptyComponent={() => {
                //         return <View flex={1} minHeight='80%' justifyContent='center' alignItems={'center'}><Text>No Records found</Text></View>
                //     }}
                //     ItemSeparatorComponent={() => <View height={2} />}
                //     style={{
                //         flex: 1,
                //         backgroundColor: '#F5F7F8',
                //     }}
                //     contentContainerStyle={{
                //         border: 'none',
                //         padding: 16,
                //         paddingBottom: 200,
                //     }}
                // />
            }


            <OrderSummarySheet
                // loading={}
                disabled={Object.values(items)?.length < 1}
                onPress={() => {
                    if (user?.uid) {
                        return navigation.navigate('Booking')
                        // if (user?.defaultLocation) {
                        //     return navigation.navigate('Booking')
                        // } else {
                        //     return navigation.navigate('AddNewLocation')
                        // }
                    }
                    dispatch(SetLoginRequired({ open: true, callBack: () => navigation.navigate('Booking') }))

                    // dispatch(SetLoginRequired({ open: true, callBack: () => user?.defaultLocation?.anonimous ? navigation.navigate('AddNewLocation') : navigation.navigate('Booking') }))

                }} />
        </View>

    );
};

export default Index;