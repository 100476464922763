// import React from 'react';
// import { Text as TextCopmnet } from 'native-base'
// import { Bold, Medium, Regular, SemiBold } from '../../constants/fonts';
// import { useSelector } from 'react-redux';
// import { Platform, useWindowDimensions } from 'react-native';
// import RenderHtml, { defaultSystemFonts } from 'react-native-render-html';
// function stripedHtml(s) {
//     return s?.replace(/<[^>]+>/g, '')
// };
// const Text = (props) => {
//     const { width } = useWindowDimensions();
//     const { locale } = useSelector(state => state.locale)
//     const { fontFamily } = props;
//     let fontSize = props?.style?.fontSize || props?.fontSize || 14
//     let fontColor = props?.color || '#334155'
//     if (props.richText) {
//         const style = {
//             color: fontColor,
//             fontFamily: (fontFamily === 'bold' ?
//                 Bold(locale)
//                 : fontFamily === 'semiBold' ?
//                     SemiBold(locale)
//                     : fontFamily === 'medium' ?
//                         Medium(locale)
//                         : Regular(locale)),
//             ...props.style,
//             fontSize: width > 400 ? (fontSize) + 2 : fontSize,
//             margin: 0,
//             padding: 0,
//             textAlign: 'left',
//             lineHeight: 18,

//         }
//         const systemFonts = [...defaultSystemFonts, 'Tajawal-Bold', 'Poppins-Bold', 'Tajawal-Medium', 'Poppins-SemiBold', 'Tajawal-Medium', 'Poppins-Medium', 'Tajawal-Regular', 'Poppins-Regular'];

//         return (
//             <RenderHtml
//                 contentWidth={width}
//                 systemFonts={systemFonts}
//                 baseStyle={{ ...style }}
//                 tagsStyles={{
//                     div: { ...style },
//                     a: { ...style },
//                     p: { ...style },
//                     h1: { ...style },
//                     ul: { ...style, paddingHorizontal: 18, marginVertical: 4 },
//                     li: { ...style, marginBottom: 3, paddingHorizontal: 4 },
//                 }}
//                 source={{
//                     html: `${props.children}`
//                 }}
//             />
//         )
//     }
//     return (
//         <TextCopmnet
//             ellipsizeMode='tail'
//             textAlign='left'

//             {...props}
//             numberOfLines={props.numberOfLines}
//             style={{
//                 color: fontColor,
//                 fontFamily: (fontFamily === 'bold' ?
//                     Bold(locale)
//                     : fontFamily === 'semiBold' ?
//                         SemiBold(locale)
//                         : fontFamily === 'medium' ?
//                             Medium(locale)
//                             : Regular(locale)),
//                 ...props.style,
//                 fontSize: width > 400 ? (fontSize) + 2 : fontSize
//             }}
//             fontWeight={(fontFamily === 'bold' ?
//                 (Platform.OS === 'android' ? 600 : 700)
//                 : fontFamily === 'semiBold' ?
//                     600
//                     : fontFamily === 'medium' ?
//                         500
//                         : 'normal')}
//             fontSize={width > 400 ? (fontSize) + 2 : fontSize}
//         >
//             {typeof props.children === 'string' ? stripedHtml(props.children.charAt(0)?.toUpperCase() + props.children.slice(1))
//                 :
//                 props.children
//             }
//         </TextCopmnet>
//     );
// };

// export default Text;


import React from 'react';
import { Text as TextCopmnet } from 'native-base'
import { Bold, Medium, Regular, SemiBold } from '../../constants/fonts';
import { useSelector } from 'react-redux';
import { Platform, useWindowDimensions } from 'react-native';
import RenderHtml, { defaultSystemFonts } from 'react-native-render-html';
function stripedHtml(s) {
    return s?.replace(/<[^>]+>/g, '')
};
const Text = (props) => {
    const { width } = useWindowDimensions();
    const { locale } = useSelector(state => state.locale)
    const { webView } = useSelector(state => state.settings)
    const fontResizer = Platform.OS === 'web' ? 1 : 2;
    const { fontFamily } = props;
    let fontSize = props?.style?.fontSize || props?.fontSize || 14
    let fontColor = '#334155'
    if (props.richText) {
        const style = {
            color: props?.color || fontColor,
            fontFamily: !webView ? (fontFamily === 'bold' ?
                Bold(locale)
                : fontFamily === 'semiBold' ?
                    SemiBold(locale)
                    : fontFamily === 'medium' ?
                        Medium(locale)
                        : Regular(locale)) : 'normal',
            ...props.style,
            fontSize: width > 400 ? (fontSize) + fontResizer : fontSize,
            margin: 0,
            padding: 0,
            textAlign: 'left',
            lineHeight: 24,

        }
        const systemFonts = [...defaultSystemFonts, 'Tajawal-Bold', 'Poppins-Bold', 'Tajawal-Medium', 'Poppins-SemiBold', 'Tajawal-Medium', 'Poppins-Medium', 'Tajawal-Regular', 'Poppins-Regular'];
        return (
            <RenderHtml
                contentWidth={width}
                systemFonts={systemFonts}
                baseStyle={{ ...style }}
                tagsStyles={{
                    br: {
                        height: '0px',
                        padding: '20px',
                        display: 'block',
                        margin: '0px'
                    },
                    div: { ...style },
                    a: { ...style },
                    p: { ...style },
                    h1: { ...style },
                    h2: {
                        ...style,
                        color: '#334155',
                        fontFamily: Bold(locale),
                        fontSize: width > 400 ? (14 + fontResizer) : 14,
                        fontWeight: (Platform.OS === 'android' ? 600 : 700)
                    },
                    h3: { ...style },
                    ul: { ...style, paddingHorizontal: 18, marginVertical: 4 },
                    li: { ...style, marginBottom: 6, fontSize: 12, paddingHorizontal: 4 },
                }}
                source={{
                    html: typeof props.children === 'string' ? (props.children).split('<br>').join('<div style="height:20px"></div>') : props.children
                }}
            />
        )
    }
    return (
        <TextCopmnet
            ellipsizeMode='tail'
            textAlign='left'

            {...props}
            numberOfLines={props.numberOfLines}
            style={{
                color: fontColor,
                fontFamily: (fontFamily === 'bold' ?
                    Bold(locale)
                    : fontFamily === 'semiBold' ?
                        SemiBold(locale)
                        : fontFamily === 'medium' ?
                            Medium(locale)
                            : Regular(locale)),
                ...props.style,
                fontSize: width > 400 ? (fontSize + fontResizer) : fontSize
            }}
            fontWeight={!webView ? (Platform.OS !== 'web' ? (fontFamily === 'bold' ?
                (Platform.OS === 'android' ? 600 : 700)
                : fontFamily === 'semiBold' ?
                    locale === 'ar' ? 700 : 600
                    : fontFamily === 'medium' ?
                        500
                        : 'normal') : (fontFamily === 'bold' ?
                            (Platform.OS === 'android' ? 600 : 700)
                            : fontFamily === 'semiBold' ?
                                locale === 'ar' ? 600 : 500
                                : fontFamily === 'medium' ?
                                    400
                                    : 'normal')) : 'normal'}
            fontSize={width > 400 ? (fontSize + fontResizer) : fontSize}
        >
            {typeof props.children === 'string' ? stripedHtml(props.children.charAt(0)?.toUpperCase() + props.children.slice(1))
                :
                props.children
            }
        </TextCopmnet>
    );
};

export default Text;