import moment from "moment";
import { isPointInPolygon, isPointWithinRadius } from 'geolib';
import { firestore, analytics } from '../constants/firebase/config'
import { Platform, I18nManager } from "react-native";
import { auth, functions } from "../constants/firebase/config";
import { strings } from "../translations/index";
import axios from 'axios';
import { GetUser, SetUserDefaultLocation, UpdateUserWallet } from "../reducers/user";
import { LoyaltyLinkOpened, SetAlertMessage, SetAmbassador, SetLoginRequired, ToggleRedeemVoucher } from "../reducers/settings";
import { useState } from "react";
import { useEffect } from "react";
import { setOrderDiscount, UpdateOrderItems } from "../reducers/order";
import { batch } from "react-redux";
import { decode } from 'html-entities';
import Geocode from "react-geocode";
import { isAndroid, isIOS } from "react-device-detect";
import { appsFlyerLogEvent } from "./index";
import AsyncStorageStatic from '@react-native-async-storage/async-storage';
import { localeSetLanguage } from "../reducers/locale";
import RNRestart from 'react-native-restart';

export function makeid() {
    // var result = '';
    // var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // var charactersLength = characters.length;
    // for (var i = 0; i < length; i++) {
    //     result += characters.charAt(Math.floor(Math.random() *
    //         charactersLength));
    // }
    return Math.floor(Math.random(new Date().getTime()) * 90000000) + 10000000;
}


export function DecodeHTML(text) {
    return decode(text)
}
export function Localize(data) {
    let locale = strings?.getLanguage() || 'en'
    let text = data?.[locale] || data?.en || data;
    return text ? String(text) : (data ? String(data) : '')
}
export function totalPriceForItem(orderedItem) {
    let price = orderedItem?.price
    let selected = orderedItem?.selectedModifiers;
    if (!selected) {
        return price
    }
    Object.values(selected).map(value => {
        let optionPrice = value.price
        let count = value.count || 1
        price = (price * 1) + (optionPrice * count)
        return price
    })
    return price
}
export const calcTotalCost = (orderList, wallet, promo, hideExtras = true) => (dispatch, getState) => {
    const { services } = hideExtras ? {} : getState()?.settings;
    let cost = 0
    let deductedValue;
    let totalTopup = 0;
    let vatExcluded = false;
    let type = 'code'
    let service_id = Object.values(orderList)?.[0]?.service_id;
    let currentService = services?.find(r => r.id === service_id);
    Object.values(orderList).map(orderedItem => {
        if (orderedItem?.service_id === 'gRF1Mzs5Oa88zpY6puyp') {
            vatExcluded = true
        }
        let price = orderedItem.price?.total
        totalTopup = totalTopup + (orderedItem.price?.topUp * 1)
        return cost = cost + (price * orderedItem?.count)
    })
    let sub = (cost).toFixed(2)

    if (promo) {
        let { cap, discount, appliedOn } = promo || {};
        let { services } = appliedOn || {};
        let appliedOnService = services?.find(r => r.service_id === service_id)

        if (appliedOnService || (services?.length === 0)) {
            let variationsArray = appliedOnService?.variations || [];
            const containsAll = Object.keys(orderList || {})?.every(element => {
                return variationsArray?.includes(element);
            });
            if (containsAll || (variationsArray?.length === 0)) {
                let { unit, value } = discount || {};
                switch (unit) {
                    case 'percent':
                        deductedValue = cost * value / 100;
                        if (cap && deductedValue > cap) {
                            deductedValue = cap * 1
                        }
                        deductedValue = deductedValue.toFixed(2)
                        break;
                    default:
                        deductedValue = value
                        break;
                }
                cost = (cost - deductedValue);
            }
        }
    } else if (wallet?.active && wallet?.useWallet && (wallet?.points > 0 && wallet?.exp > moment().valueOf())) {
        deductedValue = cost * wallet?.capPercent / 100;
        if (deductedValue > wallet?.points) {
            deductedValue = wallet?.points
        }
        if (deductedValue > wallet?.capValue) {
            deductedValue = wallet?.capValue
        }
        type = 'wallet';
        cost = (cost - deductedValue);
    }
    if (currentService?.serviceFees) {
        cost = cost + (currentService?.serviceFees?.value * 1)
    }
    let tax = vatExcluded ? 0 : (cost * 0.05).toFixed(2)

    let post = {
        sub,
        tax,
        total: (cost).toFixed(2),
        totalTopup: totalTopup?.toFixed(2),
    }
    if (currentService?.serviceFees) {
        post.serviceFees = currentService?.serviceFees
    }
    if (wallet?.affiliate) {
        post.affiliate = wallet?.affiliate
    }
    if (deductedValue) {
        post.type = type
        post.discount = deductedValue
        post.code = (type === 'code') ? promo?.code : 'loyalty'
        post.codeId = promo?.codeId || false;
        if (post.code === 'loyalty' && wallet?.referal) {
            post.referal = wallet?.referal
        } else if (promo?.affiliate?.uid) {
            post.affiliate = promo.affiliate.uid
        }
    }

    return (post)
}
export function checkDiscount(discount, item) {
    if (discount) {
        let { appliedOn } = discount || {};
        let percent = ((discount?.percent) / 100)
        let { type, items } = appliedOn || {}
        let { key } = item || {};
        let price = totalPriceForItem(item)
        switch (type) {
            case 'All':
                return { percent: discount?.percent, price: (price * percent) }
            case 'Brands':
                if (items?.includes(item?.brand)) {
                    return { percent: discount?.percent, price: (price * percent) }
                }
                break;
            case 'Products':
                if (items?.includes(key)) {
                    return { percent: discount?.percent, price: (price * percent) }
                }
                break;
            case 'Product Categories':
                if (items?.some(ele => item['prds_category'][ele])) {
                    return { percent: discount?.percent, price: (price * percent) }
                }
                break;
            default:
                return false;
        }
    }
    return false
}


export function getDate(ts) {
    let date = moment(ts).format('MMM DD yyyy').toString()
    let time = moment(ts).format('hh:mm a').toString()
    return ({ date, time })

}


export function checkLocationsServed(servedAreas, center) {
    let existed = servedAreas?.filter(rec => {
        const bound = [
            {
                latitude: rec.viewport.north_East.lat,
                longitude: rec.viewport.north_East.lng,
            },
            {
                latitude: rec.viewport.south_west.lat,
                longitude: rec.viewport.north_East.lng,
            },
            {
                latitude: rec.viewport.south_west.lat,
                longitude: rec.viewport.south_west.lng,
            },
            {
                latitude: rec.viewport.north_East.lat,
                longitude: rec.viewport.south_west.lng,
            },
        ];
        const customerIsInWashersServeArea = isPointInPolygon(
            center,
            bound,
        );
        return customerIsInWashersServeArea ? rec : false
    })
    return existed?.length ? existed[0] : false
}


export async function applyPromo(code) {
    if (code) {
        let snap = await firestore().collection('offers').where('code', '==', code.toLowerCase()).limit(1).get()
        if (snap.empty) {
            return { error: 'Please enter valid promocode' }
        }
        let results = snap?.docs?.map(rec => {
            let data = rec.data()
            let { active, endDate } = data
            if (active) {
                if (!endDate || endDate > Date.now()) {
                    return { ...data, id: rec.id }
                } else {
                    return { error: 'this promocode is expired' }
                }
            } else {
                return { error: 'this promocode is expired' }
            }
        })
        return results[0]
    }

}

export function translateSelectedSlot(dateObj) {
    if (!dateObj) {
        return false
    }
    let { date, slot } = dateObj
    let dateString;
    if (date.weekDay) {
        dateString = `${date?.weekDay} ${date?.date}`
    } else {
        dateString = moment(date).format('MMM/DD')

    }

    let timeSting = [moment(slot?.start)?.format('hh:mm A'), moment(slot?.end)?.format('hh:mm A')].join(' - ')
    return `${dateString} at ${timeSting}`


}


export function shadow({ color, opacity, radius, offsetWidth, offsetHeight, elevation }) {
    return Platform.select({
        ios: {
            shadowColor: color,
            shadowOpacity: opacity,
            shadowRadius: radius,
            shadowOffset: {
                width: offsetWidth,
                height: offsetHeight
            }
        },
        android: {
            // backgroundColor: 'white',  // <==== HERE
            shadowColor: color,
            shadowOpacity: opacity,
            shadowRadius: radius,
            shadowOffset: {
                width: offsetWidth,
                height: offsetHeight,
            },
            elevation: elevation || 4
        },
        web: {
            boxShadow: `${offsetWidth}px ${offsetHeight}px ${radius}px 2px ${color + (opacity * 100)}`
        }
    });
}

export const GetTimingSlots = (timings) => {
    let sorted = timings?.value?.sort((a, b) => a.start - b.start);
    let optimized = sorted.reduce((val, currentVal) => {
        if (val?.[currentVal.key]) {
            val[currentVal.key].push(currentVal)
        } else {
            val[currentVal.key] = [currentVal]
        }
        return val
    }, {})

    let intersection = Object.values(optimized).reduce((val, currentVal, currentIndex) => {
        currentVal?.map((rec, index) => {
            let previous = val?.[index]
            if (previous) {
                let start = rec.start > previous.start ? rec.start : previous.start
                let end = rec.end < previous.end ? rec.end : previous.end
                val[index] = { start, end }
            } else {
                val[index] = rec
            }
            return false
        })
        return val
    }, [])
    let slots = intersection.reduce((val, currentVal) => {
        let start = currentVal.start;
        let now = moment().set('year', 1955).set('month', 0).set('date', 5);
        if (moment().format('DD') === timings?.date && (now > moment(start))) {
            let next_quarter = Math.ceil((now.get('minutes') + timings?.buffer) / 15) * 15
            start = now.set('minutes', next_quarter).valueOf();
        }
        let step = 3600000;
        for (
            let time = start;
            time <= (currentVal.end - step);
            time += step
        ) {

            let slot = {
                start: time,
                end: time + step,
                id: `${moment(time).format('hh:mm A')} - ${moment(time).add(60, 'minutes').format('hh:mm A')}`
            }
            let currentHour = moment(time).format('HH');
            let key = currentHour < 12 ? 'morning' : currentHour < 18 ? 'afternoon' : 'evening';

            if (!val?.[key].some(r => r.id === slot.id)) {
                val?.[key].push(slot)
                val[key] = val?.[key].sort((a, b) => a.start - b.start)
            }
        }
        return val
    }, {
        "morning": [],
        "afternoon": [],
        "evening": []

    })
    return slots
}
const AttachToAffiliate = (PC, aff, user) => async dispatch => {
    if (user?.wallet?.affiliate && (aff?.uid === user?.wallet?.affiliate)) {
        return
    }
    try {
        await firestore().collection('offers').doc(PC).collection('enrolled').doc(user?.uid).set({
            uid: user?.uid,
            name: user?.displayName || '-',
            expAfter: aff?.exp || 365,
        }, { merge: true })
        await dispatch(UpdateUser({ affiliate: aff.uid }))
    } catch (error) {
        console.log("errorerror", error);
    }
}

export const placeOrder = (order) => async (dispatch, getState) => {
    let { settings } = getState();

    let { items, location, date, notes, discount, role, contact, candidates, brand, customer_id, service, stripeId, payment_method, user, brandLoyalty, paymentType } = order;
    let receipt = dispatch(calcTotalCost(items, { ...user?.wallet, ...brandLoyalty, affiliate: user?.wallet?.affiliate || user?.affiliate }, discount, false));
    if (receipt) {
        receipt.outstanding = (receipt.total * 1)
        receipt.paid = 0
    }
    if (discount?.affiliate) {
        await dispatch(AttachToAffiliate(discount?.id, discount?.affiliate, user))
    }

    let orderParams = {
        bookingDate: date || { date: moment().valueOf(), time: { start: moment().valueOf(), end: moment().add(60, 'minute').valueOf() } },
        notes,
        // discount,
        location,
        service: {
            name: service.name || '',
            id: service.id,
            desc: service?.details || ''
        },
        orderNumber: makeid(),
        items,
        contact,
        receipt,
        status: 'new',
        candidates,
        // candidates: ['Zruvx3cgiR468RvEG48h'],
        created: Date.now(),
        customer_id,
        updatedBy: customer_id,
        paymentType: paymentType || '-',
        paymentStatus: 'notPaid',
        brand: {
            name: brand?.name?.en || '-',
            id: brand?.id || '-',
            parent_id: brand?.parent_id
        }
    };
    if (settings?.webView) {
        orderParams.chillOrder = true
    }
    let post = Object.keys(orderParams).reduce((val, currentKey) => {
        let currentVal = orderParams[currentKey];
        if (currentVal) {
            val[currentKey] = currentVal
        }
        return val

    }, {});

    let orderId = firestore().collection('orders').doc().id;
    let customer = role === 'concierge' ? { data: false } : { data: stripeId };
    // functions().useFunctionsEmulator('http://localhost:5000/metadoc-dev/us-central1/createPaymentIntentClient')

    let createPaymentIntent = functions().httpsCallable('createPaymentIntentClient');
    let paymentIntent = await createPaymentIntent({
        id: orderId,
        amount: Number((receipt.total * 100).toFixed(2)),
        description: service.name?.en,
        customer: customer.data,
        ...orderParams,
        host: Platform.OS === 'web' ? window.location.host : 'app.metadocapp.com',
        payment_method: payment_method,
        discount
    });
    if (paymentIntent?.data?.status === 'error') {
        throw (paymentIntent?.data)
    };
    if (["requires_confirmation", "requires_action"].includes(paymentIntent?.data?.status)) {
        return ({ paymentIntent: paymentIntent?.data, post, orderId, role, customer_id })
    };

    let results = paymentIntent?.data;

    post.paymentIntent = results?.id;
    post.orderUrl = results?.orderUrl;
    post.provider_order_url = results?.provider_order_url || '--';
    post.location.link = results?.mapUrl;
    post.payment_id = results?.payment_id;
    if (role === 'concierge') {
        post.customer_id = results?.userUID
        post.concierge = customer_id
    }
    await firestore().collection('orders').doc(orderId).set(post);
    try {
        let af_analyticsPost = {
            af_service: service?.name?.en,
            af_orderId: orderId,
            af_value: receipt?.total,
            af_platform: Platform.OS,
            af_revenue: receipt?.total,
            af_currency: 'AED'

        }
        let analyticsPost = {
            service: service?.name?.en,
            orderId: orderId,
            value: receipt?.total,
            platform: Platform.OS
        }
        if (receipt?.codeId) {
            analyticsPost.promocode = receipt?.code
            af_analyticsPost.promocode = receipt?.code
        }
        appsFlyerLogEvent('purchase', af_analyticsPost)
        await analytics().logEvent('purchase', analyticsPost)
    } catch (error) {
        console.log('errorerror', error)
    }


    if (role !== 'concierge') {
        try {
            let post = {};
            ["email", 'phoneNumber'].map(r => {
                if (contact[r]) {
                    post[r] = contact[r]
                }
            })
            if (contact?.name) {
                post.displayName = contact?.name
            }
            // await firestore().collection('customers').doc(customer_id).set(post, { merge: true });
            await dispatch(UpdateUser(post))
        } catch (error) {


        }
    }
    return { orderId, paymentIntent: paymentIntent?.data }
}
export const PlaceOrderAfterConfirm = ({ paymentIntent, post, orderId, role, customer_id, user }) => async dispatch => {
    let results = paymentIntent;
    let receipt = JSON.parse(JSON.stringify(post.receipt))
    receipt.outstanding = 0;
    receipt.paid = (receipt.total * 1);

    post.paymentIntent = results?.id;
    post.orderUrl = results?.orderUrl;
    post.provider_order_url = results?.provider_order_url || '--';
    post.location.link = results?.mapUrl;
    post.payment_id = results?.payment_id;
    post.paid = true;
    post.paymentStatus = 'paid';
    post.receipt = receipt;
    // post.receipt.outstanding = 0;
    // post.receipt.paid = (post.receipt.total * 1);
    post.status = 'pending';
    if (role === 'concierge') {
        post.customer_id = results?.userUID
        post.concierge = customer_id
    }
    await firestore().collection('orders').doc(orderId).set(post);
    try {
        let af_analyticsPost = {
            af_service: results?.service?.name?.en,
            af_orderId: orderId,
            af_value: results?.receipt?.total,
            af_platform: Platform.OS,
            af_revenue: results?.receipt?.total,
            af_currency: 'AED'
        }
        let analyticsPost = {
            service: results?.service?.name?.en,
            orderId: orderId,
            value: results?.receipt?.total,
            platform: Platform.OS
        }
        if (post?.receipt?.codeId) {
            analyticsPost.promocode = post?.receipt?.code
            af_analyticsPost.promocode = post?.receipt?.code
        }
        appsFlyerLogEvent('purchase', af_analyticsPost)
        await analytics().logEvent('purchase', analyticsPost)
    } catch (error) {
        console.log('errorerror', error)
    }

    if (role !== 'concierge') {
        try {
            let post = {};
            ["email", 'phoneNumber'].map(r => {
                if (contact[r]) {
                    post[r] = contact[r]
                }
            })
            if (contact?.name) {
                post.displayName = contact?.name
            }
            await dispatch(UpdateUser(post))
            // await firestore().collection('customers').doc(customer_id).set(post, { merge: true });

        } catch (error) {

        }
    }
}
export const StatusMap = {
    "new": {
        title: 'PENDING PAYMENT',
        status: 'Pending Payment',
        color: '#F5832F',
        desc: 'payment link is sent for your guest and we are awaiting payment to continue',
    },
    "pending": {
        title: 'Your booking is received',
        status: 'Confirmed',
        color: '#3498db',
        desc: 'We will match you with top-rated professionals in your area',
    },
    "accepted": {
        title: 'Service provider',
        status: 'Assigned',
        color: '#20A2DF',
        desc: '',
    },
    "ontheway": {
        title: 'Service provider',
        status: 'On the way',
        color: '#F5A12F',
        desc: '',
    },
    "active": {
        title: 'Service provider',
        status: 'Session started',
        color: '#3195a5',
        desc: '800 Doctors',
    },
    "completed": {
        title: 'Your order is Completed',
        status: 'Completed',
        color: '#3e884f',
        desc: 'Your request has been completed successfully',
    },
    "rated": {
        title: 'Your order is Completed',
        status: 'Completed',
        color: '#b69329',
        desc: 'Your request has been completed successfully',
    },
    "canceled": {
        title: 'Your booking is Canceled',
        status: 'Canceled',
        color: '#c43d4b',
        desc: '',
    },
    "rejected": {
        title: 'Your booking is Rejected',
        status: 'Rejected',
        color: '#c43d4b',
        desc: '',
    },

}

export const applyVoucher = ({ code, user }) => async (dispatch) => {
    if (user?.wallet?.affiliate) {
        return ({ status: 'error', msg: strings?.['You have already enrolled in this program'] })
    }
    let snap = await firestore().collection('offers').where('code', '==', code.toLowerCase()).get();
    if (snap.empty) {
        console.log("no promocode");
        return ({ status: 'error', msg: strings?.['Please enter valid promocode!'] })
    }
    let snapData = snap?.docs?.[0];
    let { active, affiliate } = snapData.data();
    if (active && affiliate) {
        await dispatch(RedeemAmbassador({ ambassador: { ...affiliate, PC: snapData.id } }));
        return ({ status: 'success' })
    }
    return ({ status: 'error', msg: strings?.['Please enter valid promocode!'] })


}

export async function applyPromocode({ code, orderData, preOrder = false }) {
    let { receipt } = orderData;
    if (!code) {
        return ({ status: 'error', msg: strings?.['Please enter valid promocode!'] })

    }
    if (receipt.code) {
        return ({ status: 'error', msg: strings?.['Promocode already used'] })
    }
    let snap = await firestore().collection('offers').where('code', '==', code.toLowerCase()).get();
    if (snap.empty) {
        console.log("no promocode");
        return ({ status: 'error', msg: strings?.['Please enter valid promocode!'] })
    }

    let snapData = snap?.docs?.[0];
    let { active, appliedOn, cap, deactivated, discount, limited, count, affiliate } = snapData.data();
    if (!active || deactivated) {
        return ({ status: 'error', msg: strings?.['Please enter valid promocode!'] })
    }
    if (affiliate && affiliate?.gift) {
        return ({ status: 'affiliate', ambassador: { ...affiliate, PC: snapData.id } })
    }
    if (count < 1) {
        return ({ status: 'error', msg: strings?.['Promocode expired!'] })
    }
    let customerRef = firestore().collection('offers').doc(snapData.id).collection('redeemed').doc(orderData?.customer_id)
    let redeemed = await customerRef.get();
    if (redeemed.exists) {
        if (Object.keys(redeemed.data()).length >= limited) {
            return ({ status: 'error', msg: strings?.['You exceeded maximum number of redemption!'] })
        }
    }
    let { clients, services } = appliedOn || {};
    let appliedOnService = services?.find(r => r.service_id === orderData?.service?.id)

    if (appliedOnService) {
        let variationsArray = appliedOnService?.variations
        const containsAll = Object.keys(orderData?.items || {})?.every(element => {
            return variationsArray.includes(element);
        });
        if (!containsAll) {
            return ({ status: 'error', msg: strings?.['Promocode is not applied on selected services or variations!'] })
        }
    } else {
        return ({ status: 'error', msg: strings?.['Promocode is not applied on selected services or variations!'] })
    }
    if (!clients?.includes(orderData?.brand?.id)) {
        return ({ status: 'error', msg: strings?.['Please enter valid promocode!'] })
    }

    let deductedValue;
    switch (discount.unit) {
        case 'percent':
            deductedValue = receipt.total * discount.value / 100;
            if (cap && deductedValue > cap) {
                deductedValue = cap * 1
            }
            deductedValue = deductedValue.toFixed(2)
            break;
        default:
            deductedValue = discount.value
            break;
    }
    let newTotal = (receipt.total * 1) - (deductedValue * 1);
    let newTax = (newTotal * 0.05);
    let newReceipt = { ...receipt, total: newTotal.toFixed(2), tax: newTax.toFixed(2), discount: deductedValue, code }

    if (!preOrder) {
        // functions().useFunctionsEmulator('http://localhost:5000/metadoc-dev/us-central1/applyPromoCode')

        let updateIntent = functions().httpsCallable('applyPromoCode');
        let paymentIntent = await updateIntent({
            receipt: newReceipt,
            payment_id: orderData?.payment_id,
            code,
            orderID: orderData.id
        });
        if (paymentIntent?.data?.status === 'error') {
            return ({ status: 'error', msg: strings?.['Error while processing your request!'] })
        }
        await customerRef.set({ [orderData?.id]: Date.now() }, { merge: true })
        return (newReceipt)
    }
    return { ...snapData.data(), codeId: snapData.id }

}

export const FormatAddress = (data) => {
    let addressArray = [data?.villa, data?.flat, data?.building, data?.street, data?.area, data?.emirate].filter(r => !!r);
    return addressArray.join(' - ')
}

export const ConstructAddress = (addressComponents) => {
    if (!addressComponents) {
        return ''
    }
    let { area, street, flat, villa, building, additional_directions, emirate } = addressComponents || {};
    let formattedAddres = [villa, flat, building, street, area, emirate]?.reduce((val, currentVal) => {
        if (currentVal && currentVal !== '--') {
            val?.push(Localize(currentVal))
        }
        return val
    }, [])
    return `${formattedAddres?.join(' - ')} \n ${additional_directions || ''}`
}

export const getAvailableTimings = async ({ variationsKeys, providers }) => {
    let activeProviders = await Promise.all(providers.map(async rec => {
        let candidateProvider = await variationsKeys.reduce(async (val, currentVal) => {
            let variationSnap = await firestore().collection('providers').doc(rec.id).collection('variations').doc(currentVal).get();
            if (variationSnap.exists) {
                let data = variationSnap.data();
                let newVal = await val
                newVal.push({ ...data, provider: rec.id, slots: GetDateSlots({ ...data, key: currentVal }) })
                val = newVal
            }
            return val
        }, []);
        return ({ ...rec, variationData: candidateProvider })

    }))

    let dates = activeProviders?.reduce((val, currentVal) => {
        currentVal?.variationData?.map(rec => {
            let { slots, provider } = rec;
            if (val.length === 0) {
                val = val.concat(slots.map(rec => { return ({ ...rec, candidates: [provider] }) }))
                return false
            }
            slots.map(timing => {
                let index = val.findIndex(a => a.date === timing.date);
                if (index === -1) {
                    val.push({ ...timing, candidates: [provider] })
                } else {
                    let existing = val[index];
                    existing.value = existing.value.concat(timing.value);
                    existing.candidates = [...new Set([...existing.candidates, provider])]
                    val[index] = existing
                }
                return false
            })
        })
        return val
    }, [])

    return dates

}

const GetDateSlots = (data) => {
    let { timings, key } = data;
    let dates = [];
    let step = moment();
    let weekDays = [
        { key: 'Sunday', title: 'Sun' },
        { key: 'Monday', title: 'Mon' },
        { key: 'Tuesday', title: 'Tue' },
        { key: 'Wednesday', title: 'Wed' },
        { key: 'Thursday', title: 'Thu' },
        { key: 'Friday', title: 'Fri' },
        { key: 'Saturday', title: 'Sat' },
    ];

    for (var i = 0; i < 7; i++) {
        let weekDay = weekDays[step.day()]
        let dayTimming = timings && timings[weekDay.key] ? timings[weekDay.key] : {};
        let { isOpen } = dayTimming || {}
        let value = [];
        (dayTimming && dayTimming.timings ? dayTimming.timings : []).map(rec => {
            let start = rec.startTime
            let end = rec.endTime
            value.push({
                start, end, key
            })
            return
        })

        dates.push({
            id: i,
            date: step.format('DD'),
            weekDay: weekDay.title,
            dateTimestamp: step.valueOf(),
            value: isOpen ? value : []
        })
        step.add(1, 'day')
    }
    return dates
}
export const CheckActiveCluster = async (defaultLocation, userLocation) => {
    let results = userLocation.reduce((val, rec) => {
        let { location } = rec?.geometry || {};

        let pointInRadius = isPointWithinRadius(
            {
                latitude: location?.lat,
                longitude: location?.lng
            },
            {
                latitude: defaultLocation?.center?.lat,
                longitude: defaultLocation?.center?.lng
            },
            5000
        );
        if (pointInRadius) {
            val.push(rec?.cluster)
        }
        return val
    }, [])
    return results
}

export const SetupHotelLocation = (data) => async (dispatch) => {
    let { latLng, area, locations, address } = data
    let place_id = locations?.[0].areas_list?.[0].id;
    let geometry = {
        location: {
            ...latLng
        }
    }
    let emirate = Localize(address || {})?.split(' - ')?.[Localize(address || {})?.split(' - ')?.length - 2] || '';
    let post = {
        id: makeid(),
        geometry,
        details: {
            geometry,
            area: Localize(area?.en),
        },
        latitude: latLng?.lat,
        longitude: latLng?.lng,
        address: {
            area: Localize(area?.en),
            emirate
        },
        cluster: locations?.[0].id,
        place_id: place_id,
        anonimous: true,
        created: moment().valueOf()

    };
    dispatch(SetUserDefaultLocation(post))
}

export const CheckLocationIsServed = async (servingAreas, currenrLocation) => {
    let places_ids = (servingAreas || [])?.reduce((val, currentVal) => {
        // val = val.concat(currentVal.areas_list)
        let list = currentVal.areas_list.map(r => {
            return { cluster: currentVal.id, ...r }
        })
        val = val.concat(list)

        return val
    }, [])

    let polygons = await Promise.all(places_ids.map(async r => {
        try {
            let snap = await firestore().collection('locations').doc(r?.id).get()
            // let results = axios.get(`https://nominatim.openstreetmap.org/details.php?place_id=${r?.id}&polygon_geojson=1&format=json`)
            return { ...snap?.data(), cluster: r.cluster }
        } catch (error) {
            console.log("errorerror", error);
            // let snap = await firestore().collection('locations').where('place_id', '==', r?.id).get()
            // return snap?.data()
        }

    }))

    let place = polygons.find(r => {
        let existed;
        switch (r.geojson?.type) {
            case "MultiPolygon":
                existed = Object.keys(r.geojson.coordinates).reduce((val, current) => {
                    let existed = isPointInPolygon(currenrLocation, r.geojson.coordinates[current])
                    if (existed) {
                        val = true
                    }
                    return val
                }, false)
                return existed
            default:
                let polygon = r.geojson?.coordinates
                existed = isPointInPolygon(currenrLocation, polygon)
                return existed
        }

    })
    return { place_id: place?.place_id, cluster: place?.cluster }

}

export const UpdateUser = (updates) => async (dispatch, getState) => {
    let { user } = getState();
    if (!user?.uid) {
        return { error: { code: 401, message: 'You are not Authorized!' } }
    }
    let params = JSON.parse(JSON.stringify({ ...user, ...updates, id: user?.uid }));
    if (params) {
        delete params?.fav;
        delete params?.orders;
        delete params?.defaultLocation;
        delete params?.wallet;
    }
    let idTokenResult = await auth().currentUser?.getIdTokenResult();
    if (!idTokenResult) {
        return { success: false }
    }
    console.log("idTokenResultidTokenResult");
    try {
        //test
        // await axios.post('https://metadoc.azurewebsites.net/api/AddUser?code=r88Ii_qG-8cPvPwcRX9pcUg5oXrRyZTdmeA-EvvX5RJgAzFurbilAg==',
        //live
        await axios.post('https://z754p4rjsf4cz6kz4vqjyvz3li0hktoj.lambda-url.me-south-1.on.aws/',
            JSON.stringify(params),
            {
                headers: {
                    authorizer: idTokenResult?.token,
                    user_uid: params?.uid
                }

            })

        dispatch(GetUser(params))
        return { success: true }
    } catch (error) {
        console.log("errorerrorerror", error);
        if (error?.message === "Request failed with status code 401") {
            return { error: { code: 401, message: 'You are not Authorized!' } }
        }
        return { error: { code: 401, message: 'Unknown error,Please contact us' } }
    }

}

export async function GetUserRecord(id) {
    let idTokenResult = await auth().currentUser?.getIdTokenResult();
    //test
    // let date = await axios.get(`https://4j3774bthcb743njb7bibx4jmu0nsgdy.lambda-url.me-south-1.on.aws?name=${id}`, {
    //live
    let date = await axios.get(`https://lpy6nwzoxen2f7t3ohdk6qylaa0njomy.lambda-url.me-south-1.on.aws/?name=${id}`, {
        headers: {
            authorizer: idTokenResult?.token,
            USER_UID: id
        }
    })
    return date.data

}

export const CheckUserWallet = (user, brand_id) => async dispatch => {
    firestore().collection('partners').doc(brand_id).collection('wallets').doc(user).onSnapshot(snap => {
        let data = snap?.data()
        dispatch(UpdateUserWallet(data ? { ...data, dispatched: true, useWallet: true } : { dispatched: true }))
    })
}

export const getSearchParamFromURL = (url, param) => {
    const include = url?.includes(param)

    if (!include) return null

    const params = url.split(/([?,&,=])/)
    const index = params.indexOf(param)
    const value = params[index + 2]
    return value
}

export const AttachCard = (user, params) => async dispatch => {
    let idTokenResult = await auth().currentUser?.getIdTokenResult();
    try {
        //test
        // let { data } = await axios.post('https://metadoc.azurewebsites.net/api/AttachCard?code=X0raM9EG_dbxPphXP_X1aBZw2m3wQ_rTWonrvrAXe3iwAzFuqhwibw==',
        //live
        let { data } = await axios.post('https://5tascb3bzqa7wlmkx36y7fnwua0xwfxu.lambda-url.me-south-1.on.aws/',
            JSON.stringify(params),
            {
                headers: {
                    authorizer: idTokenResult?.token,
                    USER_UID: params?.uid
                }

            })
        if (data?.newCard) {
            let post = { cards: user?.cards ? JSON.parse(JSON.stringify(user?.cards)) : [] };
            post.cards.push(data?.newCard)
            if (data.newCustomer) {
                post.stripeId = data.newCustomer
            }
            dispatch(GetUser({ ...user, ...post }))
            return { success: true }
        }
        return { error: { code: 401, message: 'Unknown error,Please contact us' } }

    } catch (error) {
        console.log("errorerrorerror", error);
        return { error: { code: 401, message: 'Unknown error,Please contact us' } }
    }
}

export const RedeemAmbassador = ({ ambassador }) => async (dispatch, getState) => {
    const { user } = getState()
    try {
        let updateUserPoints = await functions().httpsCallable('updateUserPoints')({
            partnerID: 'IlDSRL3CAgKT7XDF5fXp',
            referalUID: ambassador?.uid,
            ambassador
        });
        let { error } = updateUserPoints?.data || {}
        if (error) {
            console.log("errorerrorerrorupdateUserPoints", error);
            return
        }
        await dispatch(AttachToAffiliate(ambassador.PC, ambassador, user))

        appsFlyerLogEvent('ambassador_enroll', {
            af_user: user?.uid,
            af_ambassador_uid: ambassador?.uid,
            af_ambassador_name: ambassador?.name,
            af_value: ambassador?.gift,
        })

        analytics().logEvent('ambassador_enroll', {
            user: user?.uid,
            ambassador_uid: ambassador?.uid,
            ambassador_name: ambassador?.name,
            value: ambassador?.gift,
        })
        dispatch(SetAlertMessage({
            title: 'Gift Received',
            gift: true,
            msg: strings?.formatString('You have earned a credit of {0} AED. You can use this credit to pay for a variety of health services at home.', ambassador?.gift),
            cancelText: 'Explore services',
            alert: true,
        }))
    } catch (error) {
        console.log('errorerrorerrorerror', error)
    }
}


export const handleDynamicLinks = (link, navigation, initial = false) => async (dispatch) => {
    if (link) {
        console.log("linklinklink", link);
        let sharedPath = getSearchParamFromURL(link?.url, 'sharedPath') || '';
        console.log("sharedPath", sharedPath);
        if (sharedPath) {
            let params = {};
            switch (sharedPath) {
                case 'Services':
                    params = {
                        serviceID: getSearchParamFromURL(link?.url, 'serviceID'),
                        name: getSearchParamFromURL(link?.url, 'name')
                    }
                    break;
                case 'service_page':
                    params = {
                        id: getSearchParamFromURL(link?.url, 'id'),
                        name: getSearchParamFromURL(link?.url, 'name'),
                        type: getSearchParamFromURL(link?.url, 'type')

                    }
                    break;
                default:
                    break;
            }
            navigation.navigate(sharedPath, params)
            return
        }

        let referalName = getSearchParamFromURL(link?.url, 'referalName') || '';
        let ambassadorUID = getSearchParamFromURL(link?.url, 'ambassadorUID') || '';
        if (ambassadorUID) {
            let snap = await firestore().collection('offers').where('affiliate.uid', '==', ambassadorUID).get()
            if (!snap.empty) {
                let { affiliate } = snap?.docs?.[0]?.data();
                if (!auth().currentUser) {
                    batch(() => {
                        dispatch(SetAmbassador({ ...link, ...affiliate, PC: snap?.docs?.[0].id }))
                        dispatch(SetAlertMessage({
                            title: 'Your Gift is waiting',
                            msg: strings.formatString('{0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!', affiliate?.name ? affiliate?.name : '', affiliate?.gift),
                            confirmText: 'Signup',
                            gift: true,
                            onConfirm: () => {
                                batch(() => {
                                    dispatch(SetLoginRequired())
                                    dispatch(SetAlertMessage(false))
                                })
                            }
                        }))
                    })

                } else {
                    batch(() => {
                        dispatch(ToggleRedeemVoucher(true))
                        dispatch(SetAmbassador({ ...link, ...affiliate, PC: snap?.docs?.[0].id }))
                    })
                }
            }
        } else {
            if (!auth().currentUser && initial) {
                batch(() => {
                    dispatch(LoyaltyLinkOpened(link))
                    dispatch(SetAlertMessage({
                        title: 'Your Gift is waiting',
                        msg: strings.formatString('Your friend {0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!', referalName !== 'undefined' ? referalName : '', brandLoyalty?.gift),
                        confirmText: 'Signup',
                        gift: true,
                        onConfirm: () => {
                            batch(() => {
                                dispatch(SetLoginRequired())
                                dispatch(SetAlertMessage(false))
                            })
                        }
                    }))
                })
            } else {
                console.log("valid for new user only");
            }
        }
    }

}

export const RedeemLoyalty = ({ link, userData, brandLoyalty }) => async (dispatch, getState) => {
    console.log("linklinklinklinklink", link);
    let { creationTime } = auth().currentUser?.metadata || {};

    // if (creationTime > moment().add(30, 'minutes')) {

    //     return dispatch(SetAlertMessage({
    //         title: 'Sorry!',
    //         msg: 'Link expired',
    //         cancelText: 'Got it',
    //         alert: true,
    //     }))
    // }

    let referalUID = getSearchParamFromURL(link?.url, 'uid')
    let referalName = getSearchParamFromURL(link?.url, 'referalName')
    if (referalUID && userData?.uid) {
        console.log("referalUID", referalUID, userData?.uid, userData?.points);
        if (referalUID === userData?.uid) {
            //same user 
            batch(() => {
                dispatch(SetAlertMessage({
                    title: 'This link is yours!',
                    msg: strings?.['Share this link with your friends and family to get even more free credit in your wallet'],
                    cancelText: 'Got it',
                    alert: true,
                }))
                dispatch(LoyaltyLinkOpened(false))
            })
            return
        } else if (userData?.wallet?.points) {
            batch(() => {
                dispatch(SetAlertMessage({
                    title: 'Credit already received!',
                    msg: strings?.['You have already received your invitation gift, but you can still get more reward credit by inviting friends.'],
                    cancelText: 'Got it',
                    alert: true,
                }))
                dispatch(LoyaltyLinkOpened(false))
            })

        } else {

            try {
                let updateUserPoints = await functions().httpsCallable('updateUserPoints')({
                    partnerID: 'IlDSRL3CAgKT7XDF5fXp',
                    referalUID: referalUID
                });
                let { error } = updateUserPoints?.data || {}
                if (error) {
                    console.log("errorerrorerrorupdateUserPoints", error);
                    return dispatch(LoyaltyLinkOpened(false))
                }

                appsFlyerLogEvent('loyalty_enroll', {
                    af_user: userData?.uid,
                    af_referral_uid: referalUID,
                    af_value: brandLoyalty?.gift,
                })
                analytics().logEvent('loyalty_enroll', {
                    user: userData?.uid,
                    referral_uid: referalUID,
                    value: brandLoyalty?.gift,
                })
                batch(() => {
                    dispatch(SetAlertMessage({
                        title: 'Gift Received',
                        gift: true,
                        msg: strings?.formatString('You have earned a credit of {0} AED. You can use this credit to pay for a variety of health services at home.', brandLoyalty?.gift),
                        cancelText: 'Explore services',
                        alert: true,
                    }))
                    dispatch(LoyaltyLinkOpened(false))
                })

            } catch (error) {
                console.log('errorerrorerrorerror', error)
                dispatch(LoyaltyLinkOpened(false))
            }
        }
    } else {
        // dispatch(LoyaltyLinkOpened(false))

        console.log('already refered')
    }

}

export function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}

export const updateOrderForLocationChange = (params) => async (dispatch, getState) => {
    let { items, cluster, defaultLocation } = params;
    let cluserVariations = cluster?.variations
    let places_ids = cluster?.areas_list?.map(r => r.id)
    let alert = false;
    let updatedList;
    let udatedLocations;
    let selectedPlace;
    if (!places_ids?.includes(defaultLocation?.place_id)) {
        const { settings, user } = getState()
        let { servingAreas } = settings || {};
        if (servingAreas) {
            let { place_id, cluster } = await CheckLocationIsServed(servingAreas, { latitude: defaultLocation?.geometry?.location?.lat, longitude: defaultLocation?.geometry?.location?.lng })
            if (!place_id) {
                batch(() => {
                    dispatch(UpdateOrderItems({}))
                    dispatch(SetAlertMessage({
                        title: 'Ohh Sorry!',
                        msg: strings?.[`We apologize for any inconvenience, Some of selected services are currently not served in your area at the moment.`],
                        cancelText: 'Got it',
                        alert: true,
                    }))
                })
                return

            } else {
                udatedLocations = user?.locations?.reduce((val, currentVal) => {
                    if (currentVal?.id === defaultLocation?.id) {
                        val.push({ ...currentVal, place_id, cluster })
                        return val
                    }
                    val.push(currentVal)
                    return val

                }, [])
                cluserVariations = servingAreas?.find(rec => rec.id === cluster)?.variations
                selectedPlace = { place_id, cluster }
                // batch(() => {
                //     dispatch(UpdateUser({ locations: udatedLocations }))
                //     dispatch(SetUserDefaultLocation({ ...defaultLocation, place_id, cluster }))
                // })
            }
        }
    }

    updatedList = Object.keys(items).reduce((val, currentKey) => {
        if (cluserVariations?.[currentKey]) {
            val[currentKey] = { ...items?.[currentKey], price: { ...items?.[currentKey]?.price, total: cluserVariations?.[currentKey], val: cluserVariations?.[currentKey] } }
        } else {
            alert = true
        }
        return val
    }, {})

    batch(() => {
        dispatch(UpdateOrderItems(updatedList))
        if (udatedLocations) {
            dispatch(UpdateUser({ locations: udatedLocations }))
        }
        dispatch(SetUserDefaultLocation({ ...defaultLocation, ...selectedPlace }))

    })

    if (alert) {
        return dispatch(SetAlertMessage({
            title: 'Ohh Sorry!',
            msg: strings?.[`We apologize for any inconvenience, Some of selected services are currently not served in your area at the moment.`],
            cancelText: 'Got it',
            alert: true,
        }))

    }
    return { success: true }


}

const constructAddressObject = (addressObject) => {
    let { geometry, address_components } = addressObject

    let address = address_components?.reduce((value, currentValue) => {
        if (currentValue.types.includes("neighborhood")) {
            value.area = currentValue.short_name
        }
        if (!value.area && currentValue.types.includes("sublocality")) {
            value.area = currentValue.short_name
        }
        if (currentValue.types.includes("route")) {
            value.street = currentValue.short_name
        }
        if (currentValue.types.includes("locality")) {
            value.emirate = currentValue.short_name
        }
        return value
    }, {})

    if (geometry) {
        addressObject.geometry = {
            location: {
                lat: geometry?.location?.lat,
                lng: geometry?.location?.lng,
            },
            viewport: {
                south_west: geometry?.viewport?.southwest,
                north_East: geometry?.viewport?.northeast
            }
        }

        let center = { lat: geometry.location.lat, lng: geometry.location.lng }
        return ({ center, addressObject, address })
    }
}

export const UpdateCurrnetLanguage = (selectedLang) => async (dispatch) => {
    let lang = await AsyncStorageStatic.getItem('language');
    if (lang === selectedLang) {
        return
    }
    dispatch(localeSetLanguage(selectedLang))
    I18nManager.forceRTL(selectedLang === 'ar' ? true : false);
    strings.setLanguage(selectedLang);
    AsyncStorageStatic.setItem('language', selectedLang);
    if (Platform.OS !== 'web') {
        setTimeout(() => {
            RNRestart.Restart()
        }, 1000);
    } else {
        I18nManager.allowRTL(selectedLang === 'ar' ? true : false);
        // window.location.reload()
    }

}

export function CompareVersions(version1, version2) {
    // split the versions into arrays of numbers
    const version1Parts = version1.split('.').map(Number);
    const version2Parts = version2.split('.').map(Number);

    // compare the parts from left to right
    for (let i = 0; i < Math.max(version1Parts.length, version2Parts.length); i++) {
        // if the current part is undefined, assume it is 0
        const part1 = version1Parts[i] || 0;
        const part2 = version2Parts[i] || 0;

        // if the parts are different, return 1 if the first is greater, -1 if the second is greater, or 0 if they are equal
        if (part1 !== part2) {
            return part1 > part2;
        }
    }

    // if all parts are equal, return 0
    return 0;
}
export const GetGlobalPromos = (snap, clinentID) => (dispatch) => {
    snap.forEach(rec => {
        let { active, appliedOn } = rec.data();
        if (active && appliedOn?.clients?.includes(clinentID)) {
            let promo = { ...rec.data(), id: rec.id, codeId: rec.id }
            dispatch(setOrderDiscount(promo))
        }
    })
}


export const CheckValidPromoForUser = (uid) => async (dispatch, getState) => {
    let { discount } = getState()?.order;
    if (discount) {
        let { limited } = discount || {};
        let customerRef = firestore().collection('offers').doc(discount.id).collection('redeemed').doc(uid)
        let redeemed = await customerRef.get();
        if (redeemed.exists) {
            if (Object.keys(redeemed.data()).length >= limited) {
                dispatch(setOrderDiscount(false))
            }
        }
    }
}



export const ChillCheckLocationServed = (servingAreas, center) => async dispatch => {

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API);
    Geocode.setRegion("ae");
    Geocode.setLanguage('en')
    Geocode.setLocationType("GEOMETRIC_CENTER");

    let response = await Geocode.fromLatLng(center.latitude, center.longitude)
    const address = response?.results?.[0];
    let result = constructAddressObject(address);
    let { place_id, cluster } = await CheckLocationIsServed(servingAreas, center)
    let ref = isIOS ? window?.webkit?.messageHandlers : window;
    if (!place_id) {
        return {
            error: {
                title: 'Sorry',
                msg: strings?.['We apologize for any inconvenience, but we are not serving your area at the moment!'],
                alert: true,
                onCancel: () => {
                    dispatch(SetAlertMessage(false))
                    ref.ReactNativeWebView.postMessage(JSON.stringify({ dismissApp: true }));
                }

            }
        }
    }

    let post = {
        ...result,
        id: makeid(),
        geometry: result.addressObject,
        details: result.addressObject,
        latitude: center.latitude,
        longitude: center.longitude,
        address: result.address
    };

    return ({ success: { ...post, created: moment().valueOf(), place_id, cluster, anonimous: true } })
}