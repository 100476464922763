import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Center, VStack, Skeleton, Flex, FlatList, Image, ScrollView } from "native-base";
import PowerdBy from '../../shared/PowerdBy';
import { FlushOrder, SetOrderService } from '../../../reducers/order';
import HomeCard from '../../shared/HomeCard';
import FixedHeader from './com/FixedHeader';
import { Localize, shadow } from '../../../helpers/helpers';
import Text from '../../shared/TextLabel';
import { SetRequiredLocation } from '../../../reducers/settings';
import { strings } from '../../../translations/index';
import ScalableImage from "../../shared/ScalableImage";
import { Dimensions } from 'react-native';

const Home = ({ navigation }) => {
    const { brand, services, webView } = useSelector(state => state.settings);
    //don't remove this!!!!!
    const { locale } = useSelector(state => state.locale);
    const dispatch = useDispatch();

    return (
        <ScrollView
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false} >
            {
                !webView && <FixedHeader data={brand} />
            }
            <View flex={1} minHeight={'118vh'} >
                {!webView &&
                    <View
                        style={{ padding: 16, paddingTop: 24 }}>
                        {/* <Image
                            shadow={shadow({ color: '#00000012', radius: 10, offsetWidth: 0, offsetHeight: 0 })}
                            width={'100%'}
                            height='100%'
                            borderRadius={8}
                            source={{ uri: brand?.picture?.[0] }}
                        /> */}

                        {brand?.picture?.[0] && <ScalableImage
                            shadow={shadow({ color: '#00000012', radius: 10, offsetWidth: 0, offsetHeight: 0 })}
                            style={{ borderRadius: 8 }}
                            width={Dimensions.get('window').width > 900 ? (Dimensions.get('window').width * 0.3) - 16 : Dimensions.get('window').width - 16}
                            uri={brand?.picture?.[0]}
                            source={{ uri: brand?.picture?.[0], cache: 'force-cache' }}
                        />}
                    </View>

                }
                <View style={{ paddingHorizontal: 16, paddingTop: 12 }}>
                    {!webView && <Text
                        fontSize={18}
                        fontFamily='medium'
                    >
                        {strings["Available Services"]}
                    </Text>}
                    <FlatList
                        contentContainerStyle={{
                            paddingVertical: 12,
                            // paddingBottom: 120
                        }}
                        data={services}
                        renderItem={({ item }) => <View
                            marginBottom={5}
                            key={item.id}
                        >
                            <HomeCard data={item} book={() => {
                                const action = () => {
                                    // dispatch(FlushOrder());
                                    dispatch(SetOrderService(item));
                                    switch (item?.menuType) {
                                        case "searchable":
                                            return navigation.navigate('Pharmacy', { serviceID: item.id, title: Localize(item?.name) })
                                        default:
                                            navigation.navigate('Services', { serviceID: item.id, name: item?.name?.en?.replace(/ /g, "_") })
                                    }
                                }

                                if (webView) {
                                    return action()
                                }
                                // if (!defaultLocation || (moment(defaultLocation?.created).add(1, 'minute')).isBefore(moment())) {
                                if (brand?.customLocations) {
                                dispatch(SetRequiredLocation({
                                    open: true,
                                    action: action
                                }))
                                }else{
                                    action()
                                }
                                return
                                // } else {
                                //     action()
                                // }

                            }} />
                        </View>}
                        keyExtractor={item => item.id}
                    />
                </View>
                <PowerdBy />
            </View >
        </ScrollView >
    );
};
export default Home;