import { Flex, Pressable, ScrollView, View, useToast, Image, Link, Button } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../shared/TextLabel';
import CardComponent from './com/CardComponent';
import CustomImage from '../../shared/customImg'
import { Calender, DashedLine } from '../../../constants/imgs';
import ActionsSheet from './com/ActionsSheet';
import Badge from '../../shared/Badge';
import moment from 'moment';
import { ConstructAddress, Localize, shadow } from '../../../helpers/helpers';
import { firestore } from '../../../constants/firebase/config';
import { useNavigation } from '@react-navigation/native';
import OrderHeader from './com/OrderHeader';
import { Linking, Platform } from 'react-native';
import QRcodeModal from './com/QRcodeModal';
import { SetAlertMessage, SetLoginRequired } from '../../../reducers/settings';

const Index = ({ route }) => {
    const [providerView] = useState(route.name === 'provider_order')
    // const [providerView] = useState(true)

    const { order_id } = route.params
    const navigation = useNavigation();
    const { orders, uid } = useSelector(state => state?.user)
    const { brand, webView } = useSelector(state => state?.settings)
    const { strings } = useSelector(state => state?.locale)
    const [activeOrder, setActiveOrder] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        if (!uid) {
            return dispatch(SetLoginRequired({ dismissable: false }))
        }
        let reff;
        if (orders?.length && orders?.find(r => r.id === order_id)) {
            setActiveOrder(orders?.find(r => r.id === order_id))
            navigation.setParams({ data: orders?.find(r => r.id === order_id) })

        } else {
            reff = firestore().collection('orders').doc(order_id).onSnapshot(snap => {
                if (!snap.exists) {
                    return navigation.reset({
                        index: 0,
                        routes: [{ name: 'Home' }],
                    })
                }
                navigation.setParams({ ...snap.data(), id: order_id })
                setActiveOrder({ ...snap.data(), id: order_id })
            })
        }
        return () => {
            if (reff) {
                reff()
            }
        }
    }, [orders, uid])

    // const { activeProviders } = useSelector(state => state.settings)
    // let assigndProvider = activeProviders?.find(r => r.id === activeOrder?.provider?.id)

    const [state, setState] = useState({})
    const [qrCode, toggleQrCode] = useState(false)

    // const cardStatus = ['accepted', 'ontheway', 'active'].includes(activeOrder?.status) ? { ...StatusMap?.[activeOrder?.status], desc: Localize(activeOrder?.provider?.name) } : StatusMap?.[activeOrder?.status];
    const Toast = useToast();
    useEffect(() => {

        let items = activeOrder?.items && Object.keys(activeOrder.items)?.map(key => {
            let item = activeOrder.items[key];
            return ({ name: Localize(item.name), id: key, count: item.count, price: item?.price?.total, description: item?.description })
        })
        let addons = activeOrder?.addons && Object.keys(activeOrder.addons)?.map(key => {
            let item = activeOrder.addons[key];
            return ({ name: Localize(item.name), id: key, count: item.count, price: item?.price, description: item?.description })
        })
        if (addons) {
            items = items.concat(addons)
        }
        let orderDetails = [
            //     {
            //     title: 'Order Status',
            //     type: 'tag',
            //     val: cardStatus,

            // },
            {
                title: 'Payment Status',
                type: 'text',
                val: activeOrder?.paid ? strings?.['Paid'] : strings?.['Not Paid (To be collected)'],
                color: activeOrder?.paid ? '#81D146' : '#EF4444'
            },
            {
                title: 'Order ID',
                type: 'text',
                val: activeOrder?.orderNumber

            },
            {
                title: 'Customer Name',
                type: 'text',
                val: activeOrder?.contact?.name || '--'

            },
            {
                title: 'Customer Number',
                type: 'phoneNumber',
                val: activeOrder?.contact?.phoneNumber || '--'

            },

            {
                title: 'Service',
                type: 'text',
                val: Localize(activeOrder?.service?.name) || '--'

            },
            // {
            //     title: 'Service Details',
            //     type: 'text',
            //     val: items?.map(r => `X${r.count} ${r.name} `).join(', ')

            // },
            // {
            //     title: 'Service Provider',
            //     type: 'text',
            //     val: Localize(activeOrder?.provider, 'name') || 'Not assigned'

            // },
            {
                title: 'Instructions',
                type: 'text',
                val: activeOrder?.notes || '--'

            }]

        if (providerView) {
            orderDetails = orderDetails.filter(r => r.title !== 'Customer Number')
        }
        setState({ data: items, orderDetails })
    }, [activeOrder]);



    return (
        <View flex={1} height={webView ? '100vh' : 'auto'}>
            <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} bounces={false} contentContainerStyle={{ paddingBottom: 100 }} >
                <OrderHeader title='Order Details' data={activeOrder} />
                <View style={{ paddingHorizontal: 16, paddingVertical: 42 }}>

                    <Flex>

                        <View paddingTop={4} >
                            <View backgroundColor={'#fff'} padding={4} borderRadius={10} shadow={shadow({ color: '#00000010', radius: 8, offsetWidth: 1, offsetHeight: 1 })}>
                                <Text fontFamily='semiBold' paddingBottom={2} fontSize={16}>
                                    {/* {strings.Details} */}
                                    {strings?.["Overview"]}
                                </Text>
                                {
                                    state?.orderDetails?.map(rec =>
                                        <View
                                            paddingBottom={4}
                                            key={rec.title}
                                            flexDirection={'row'} justifyContent='space-between'>
                                            <Text fontSize={12} >
                                                {strings?.[rec.title]}
                                            </Text>
                                            {
                                                rec.type === 'tag' ?
                                                    <Badge title={strings?.[rec?.val?.status]} color={rec?.val?.color} />
                                                    :
                                                    rec.type === 'phoneNumber' ?

                                                        <Link href={`tel:${rec.val}`} maxWidth={'50%'} >
                                                            <Text fontSize={12} style={{ color: '#2F8AF4' }} textAlign='right'>
                                                                {rec.val}
                                                            </Text>
                                                        </Link>

                                                        :
                                                        <Text fontSize={12} maxWidth={'50%'} textAlign='right' style={{ color: rec.color }}>
                                                            {rec.val}
                                                        </Text>
                                            }
                                        </View>)
                                }

                            </View>
                        </View>
                    </Flex>

                    <Flex>

                        <View paddingTop={4} >
                            <View backgroundColor={'#fff'} padding={4} borderRadius={10} shadow={shadow({ color: '#00000010', radius: 8, offsetWidth: 1, offsetHeight: 1 })}
                            >
                                <Text fontFamily='semiBold' paddingBottom={2} fontSize={16}>
                                    {strings.Details}
                                    {/* {strings?.["Payment details"]} */}
                                </Text>
                                {
                                    state?.data?.map(rec => {
                                        return (<View key={rec.id}>
                                            <View flexDirection={'row'} justifyContent='space-between'>
                                                <Text
                                                    // fontFamily='semiBold'
                                                    w={'70%'}
                                                    // fontWeight={400}
                                                    style={{
                                                        fontSize: 12,
                                                    }} >
                                                    <Text fontFamily='semiBold'>
                                                        X{rec.count}
                                                    </Text> {rec.name}
                                                </Text>
                                                {
                                                    (!providerView || (providerView && !activeOrder?.paid)) && <Text
                                                        fontFamily='semiBold'
                                                        style={{
                                                            fontSize: 12,
                                                        }} > {rec.price} {strings?.AED}</Text>
                                                }

                                            </View>
                                            <Text w={'70%'} fontWeight={400} style={{ fontSize: 10 }} >
                                                {rec.description}
                                            </Text>
                                        </View>)
                                    })
                                }
                                {(!providerView || (providerView && !activeOrder?.paid)) && <View>
                                    <View w={'100%'} marginY={2}>
                                        <CustomImage
                                            svg
                                            width={'100%'}
                                            height={10}
                                            uri={DashedLine}
                                        />
                                    </View>
                                    {
                                        activeOrder?.receipt?.discount && <View flexDirection={'row'} paddingBottom={1} justifyContent='space-between'>
                                            <Text fontFamily='bold' fontSize={12}>
                                                {strings.Discount}
                                            </Text>
                                            <Text fontSize={12} style={{ color: '#32A2DC' }} >
                                                -{activeOrder?.receipt?.discount} {strings.AED}
                                            </Text>
                                        </View>
                                    }
                                    {
                                        activeOrder?.receipt?.serviceFees && <View flexDirection={'row'} paddingBottom={1} justifyContent='space-between'>
                                            <Text fontFamily='bold' fontSize={12}>
                                                {Localize(activeOrder?.receipt?.serviceFees?.title)}
                                            </Text>
                                            <Text fontSize={12} style={{ color: '#32A2DC' }} >
                                                {activeOrder?.receipt?.serviceFees?.value} {strings.AED}
                                            </Text>
                                        </View>
                                    }
                                    <View flexDirection={'row'} justifyContent='space-between'>
                                        <Text
                                            fontFamily='bold'
                                            style={{
                                                fontSize: 12,
                                            }}>
                                            {strings.Total}
                                        </Text>
                                        <Text
                                            fontFamily='bold'
                                            style={{
                                                fontSize: 12,
                                            }}>
                                            {activeOrder?.receipt?.total} {strings.AED}
                                        </Text>
                                    </View>
                                    {
                                        providerView && !activeOrder?.paid && <View paddingTop={2}>
                                            <Button borderRadius={8} onPress={() => {
                                                toggleQrCode(activeOrder.id)
                                                console.log("####", qrCode);
                                            }} >
                                                <Text fontFamily='medium' style={{ color: '#ffffff' }}>{strings?.["Collect Payment Via QR Code"]}</Text>
                                            </Button>
                                        </View>
                                    }


                                </View>}
                            </View>

                        </View>


                    </Flex>
                    <Flex>
                        <View paddingTop={4} shadow={shadow({ color: '#00000010', radius: 8, offsetWidth: 1, offsetHeight: 1 })} >
                            <CardComponent
                                data={{
                                    title: strings?.['Date & Time'],
                                    desc: <View paddingTop={2}>
                                        <Text fontSize={12} fontFamily='semiBold'>{activeOrder?.bookingDate?.time?.id}</Text>
                                        <Text fontSize={12} paddingTop={1}>{moment(activeOrder?.bookingDate?.date).format('DD, MMMM yyyy (dddd)')}</Text>

                                    </View>
                                }} >
                                <Pressable
                                    w={30}
                                    h={30}
                                    style={{ backgroundColor: '#EBF2F9' }}
                                    justifyContent='center'
                                    alignItems={'center'}
                                    borderRadius={8} >
                                    <CustomImage
                                        svg
                                        fill='#20A2DF'
                                        uri={Calender}
                                        width={25}
                                        height={25}
                                    />
                                </Pressable>

                            </CardComponent>
                        </View>
                    </Flex>
                    <Flex>
                        <View paddingTop={4} >
                            <CardComponent
                                data={{
                                    title: strings?.[`Address`],
                                    desc: <View paddingTop={2}>
                                        {
                                            brand?.customLocations ?
                                                <Text fontSize={12}>
                                                    {strings?.["Flat number:"]} <Text fontFamily='semiBold'> {activeOrder?.location?.flat}</Text>
                                                </Text>
                                                :
                                                <Text fontSize={12}>
                                                    {strings?.["Room number:"]} <Text fontFamily='semiBold'> {activeOrder?.contact?.room}</Text>
                                                </Text>

                                        }
                                        <Text paddingTop={1}>
                                            {ConstructAddress(activeOrder?.location)}
                                        </Text>
                                    </View>
                                }} >
                                <Pressable
                                    width={20}
                                    height={20}
                                    // w={30}
                                    // h={30}
                                    borderWidth={1}
                                    borderColor={'#C6D0D6'}
                                    onPress={() => {
                                        if (activeOrder?.location?.link) {
                                            Linking.openURL(activeOrder?.location?.link)
                                            // navigator.clipboard.writeText(activeOrder?.location?.link);
                                            // Toast.show({
                                            //     title: 'Link copied to clipboard',
                                            //     placement: 'top'
                                            // })
                                        }
                                    }}
                                    style={{ backgroundColor: '#EBF2F9' }}
                                    justifyContent='center'
                                    alignItems={'center'}
                                    borderRadius={8}
                                >
                                    <Image
                                        alt='location'
                                        width={20}
                                        height={80}
                                        style={{
                                            height: 80,
                                            borderRadius: 8
                                        }}
                                        resizeMode="contain"
                                        source={{ uri: `https://maps.googleapis.com/maps/api/staticmap?center=${activeOrder?.location?.latLng?.lat},${activeOrder?.location?.latLng?.lng}&zoom=13&size=150x150&markers=color:red%7C${activeOrder?.location?.latLng?.lat},${activeOrder?.location?.latLng?.lng}&sensor=false&key=AIzaSyCkssbTaiK2IJ7LPEHuUvWZe_6B3mzbgqU` }}
                                    />
                                </Pressable>

                            </CardComponent>
                        </View>
                    </Flex>
                </View>
                {
                    qrCode && <QRcodeModal qrCode={qrCode} dismiss={() => toggleQrCode(false)} />
                }
            </ScrollView >
            {
                (uid && ((uid === activeOrder.customer_id) || (uid === activeOrder.concierge))) ?
                    <View
                        position={Platform.OS === 'web' ? 'sticky' : 'absolute'}
                        width='100%'
                        bottom={0}>
                        {

                            ['new', 'pending', 'accepted'].includes(activeOrder?.status) ? <ActionsSheet
                                pharmacy={activeOrder?.service?.id === 'pharmacy'}
                                onCancel={() => {
                                    dispatch(SetAlertMessage({
                                        title: 'Cancel Request?',
                                        msg: strings?.['Are you sure you want to cancel this request?'],
                                        confirmText: 'Cancel Request',
                                        onConfirm: async () => {
                                            await firestore().collection('orders').doc(activeOrder.id).update({
                                                status: 'canceled',
                                                canceled: Date.now(),
                                                updatedBy: activeOrder.customer_id,

                                            })
                                            dispatch(SetAlertMessage(false))
                                        }
                                    }))
                                }}
                                onConfirm={() => {
                                    navigation.navigate('Reschedule', {
                                        rescadual: {
                                            id: activeOrder.id,
                                            variationsKeys: Object.keys(activeOrder.items)
                                        }
                                    })
                                }}
                            />
                                :
                                ['ontheway', 'completed'].includes(activeOrder?.status) ? <ActionsSheet
                                    type={'single'}
                                    disabled={activeOrder?.status === 'ontheway' && activeOrder?.delayed}
                                    color={activeOrder?.status === 'ontheway' ? '#3B3B3B' : '#F5B82F'}
                                    btnTitle={activeOrder?.status === 'ontheway' ? 'Report Delay' : 'Rate Booking experience'}
                                    onConfirm={() => {
                                        if (activeOrder.status === 'ontheway') {
                                            dispatch(SetAlertMessage({
                                                title: 'Report Delay?',
                                                msg: strings?.['Are you sure you want to send this request?'],
                                                confirmText: 'Report',
                                                onConfirm: async () => {
                                                    await firestore().collection('orders').doc(activeOrder.id).update({
                                                        delayed: Date.now(),
                                                        updatedBy: activeOrder.customer_id,

                                                    })
                                                    Toast.show({
                                                        title: 'Delay reported to customer support',
                                                        placement: 'top'
                                                    })
                                                    dispatch(SetAlertMessage(false))
                                                }
                                            }))


                                        } else {
                                            navigation.navigate('Rating', {
                                                order_id: activeOrder.id
                                            })
                                        }

                                    }}
                                />

                                    :
                                    <View />
                        }
                    </View>
                    :
                    <View />
            }

        </View >

    );
};

export default Index;