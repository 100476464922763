import Axios from 'axios';
import moment from 'moment';
import { createAction } from 'redux-action';
import { firestore } from '../constants/firebase/config';
import { DecodeHTML } from '../helpers/helpers';
export const GET_PRDS = 'GET_PRDS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_BANNER = 'GET_BANNER';
export const GET_BRANDS = 'GET_BRANDS';
export const GET_MEDICINES = 'GET_MEDICINES';

export const GetPrds = createAction(GET_PRDS, (list) => {
    return list
});
export const GetCategories = createAction(GET_CATEGORIES, async () => {
    let snap = await firestore().collection('variations_categories').get()
    let data = snap.docs.map(r => {
        return ({ ...r.data(), id: r.id })
    })
    return data
});
export const GetBanner = createAction(GET_BANNER, (list) => {
    return list
});
export const GetBrands = createAction(GET_BRANDS, (list) => {
    return list
});

export const GetMedicines = createAction(GET_MEDICINES, async (existed) => {
    try {

        let snap = await firestore().collection('medicines').get()
        let results = snap.docs.map(r => ({ ...r.data(), id: r?.data()?.sku, name: DecodeHTML(r?.data()?.name) }))?.filter(r => r.category_name !== 'UNLISTED')
        if (results) {
            let categories = results.reduce((val, currentVal) => {
                if (!val.find(r => r.id === currentVal?.category_id)) {
                    val.push({ name: DecodeHTML(currentVal?.category_name), id: currentVal?.category_id })
                }
                return val
            }, [])
            return { categories: categories, prds: results, created: moment().valueOf() }

        }
        return []

    } catch (error) {
        console.log("errorerrorerrorerror", error);
    }
    return []
})



const initial = { prds: [], categories: [] };

let ex = (state = initial, action) => {
    switch (action.type) {
        case GET_PRDS:
            return { ...state, prds: action.payload || [] }
        case GET_CATEGORIES:
            return { ...state, categories: action.payload || [] }
        case GET_BANNER:
            return { ...state, banner: action.payload || [] }
        case GET_BRANDS:
            return { ...state, brands: action.payload || [] }
        case GET_MEDICINES:
            return { ...state, medicines: action.payload || [] }

        default:
            return state;
    }
};
export default ex