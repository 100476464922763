import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { Image, View } from 'native-base';
import React from 'react';
import { Platform, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Localize } from '../../helpers/helpers';
import { SetRequiredLocation } from '../../reducers/settings';
import Text from './TextLabel';

const ServicesCard = ({ data }) => {
    const { width } = useWindowDimensions()
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const { defaultLocation } = useSelector(state => state.user)
    const { servingAreas, webView } = useSelector(state => state.settings)
    const { medicines } = useSelector(state => state.helpers)

    let picture = data?.picture?.[0] || data?.image
    return (
        <TouchableOpacity
            disabled={data?.comingSoon}
            onPress={() => {
                if (data?.comingSoon) {
                    return
                }
                if (data?.service_id === 'pharmacy') {
                    navigation.push('service_page', { id: data?.sku, type: 'medicine', key: 'APage' + data?.id })
                    return

                }
                if (!defaultLocation || (moment(defaultLocation?.created).add(15, 'minute')).isBefore(moment())) {
                    dispatch(SetRequiredLocation({
                        open: true,
                        action: (cluster) => {
                            // let activeCluster = servingAreas?.find(r => r.id === cluster)
                            // if (Object.keys(activeCluster?.variations || {}).includes(data?.id)) {
                            // let similar = data?.similar?.filter(r => Object.keys(activeCluster?.variations || {}).includes(r?.id))
                            navigation.push('service_page', { id: data?.id, key: 'APage' + data?.id })
                            // navigation.push('service_page', { data: { ...data, pricing: { ...data.pricing, total: activeCluster?.variations[data?.id] }, similar }, key: 'APage' + data?.id })
                            // }
                        }
                    }))
                    return
                }
                navigation.push('service_page', { id: data?.id, key: 'APage' + data?.id })

                // navigation.push('service_page', { data: data, key: 'APage' + data?.id })
            }}
            style={{
                borderRadius: 12,
                backgroundColor: '#fff',
                width: (width > 900 ? 450 : (Platform.OS === 'web' ? (window.screen.width * 0.3) : width)) / 2.4,
                minWidth: 150,
                overflow: 'hidden'
            }}
            borderRadius={12}
            backgroundColor='#fff'
            width={(width > 900 ? 450 : (Platform.OS === 'web' ? (window.screen.width * 0.3) : width)) / 2.4}
            minW={150}
            overflow='hidden'>
            <Image
                alt='image'
                style={{
                    aspectRatio: 1.3,
                    height: webView ? ((width > 900 ? 450 : window.screen.width * 0.3) / 2.4) / 1.3 : 'auto',
                    minHeight: webView ? 150 / 1.3 : 'auto'
                }}
                resizeMode='cover'
                source={{ uri: picture }}
            />
            <View justifyContent={'space-between'} flex={1}>
                <Text padding={3} fontFamily='medium' style={{ fontSize: 12 }} numberOfLines={Platform.OS !== 'web' ? 2 : 3}>
                    {Localize(data.name)}
                </Text>
                <View alignItems={'center'} padding={3}>
                    <View
                        style={{
                            width: '100%',
                            alignItems: 'center',
                            backgroundColor: data?.comingSoon ? '#cacaca20' : '#20A2DF12',
                            paddingVertical: 8,
                            borderRadius: 8
                        }}>
                        <Text fontFamily='medium' style={{ color: data?.comingSoon ? '#cacaca' : '#20A2DF', fontSize: 12 }}>
                            {data?.actionBtnTitle || 'Book Now'}
                        </Text>
                    </View>
                </View>
            </View>

        </TouchableOpacity>
    );
};

export default ServicesCard;