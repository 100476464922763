import React from 'react';
import { Input as CustomInput } from 'native-base'
const Input = (props) => {
    return (
        <CustomInput
            focusOutlineColor="transparent"

            // onBlur={() => {
            //     if (Platform.OS === 'web') {
            //         window.scrollTo(0, 0)
            //     }
            // }}
            // onSubmitEditing={()=>{
            //     Keyboard.dismiss
            //     window.scrollTo(0, 0)
            // }}
            // blurOnSubmit
            // _focus={
            //     { borderColor: '#fff' }
            // }
            textAlign={'left'}
            fontFamily='Poppins-Medium'
            padding={4}
            backgroundColor={'#fff'}
            borderRadius={8}
            borderColor={'#fff'}
            height={50}

            {...props}

        />
    );
};
export default Input;
