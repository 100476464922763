import { Flex, Pressable, ScrollView, useToast, View } from 'native-base';
import React, { useState } from 'react';
import Text from '../../../shared/TextLabel';
import Image from '../../../shared/customImg'
import { EmptyDot, FilledDot } from '../../../../constants/imgs';
import OrderSummarySheet from '../../../shared/OrderSummarySheet';
import { batch, useDispatch, useSelector } from 'react-redux';
import { SetOrderLocation } from '../../../../reducers/order';
import { useNavigation } from '@react-navigation/native';
import { FormatAddress } from '../../../../helpers/helpers';
import { Platform } from 'react-native';
import { useEffect } from 'react';
import { SetUserDefaultLocation } from '../../../../reducers/user';
import moment from 'moment';
import { strings } from '../../../../translations/index';

const Address = ({ route }) => {
    let { params } = route
    let hideActions = params?.hideActions
    const [state, setState] = useState(false)
    const dispatch = useDispatch();
    const { locations, defaultLocation } = useSelector(state => state?.user);
    const { service } = useSelector(state => state?.order);

    const toast = useToast();
    const Navigation = useNavigation()
    useEffect(() => {
        if (defaultLocation) {
            let existed = locations.find(r => r?.id === defaultLocation?.id)
            if (existed) {
                setState(defaultLocation?.id)
            }
        }

    }, [locations])
    return (
        <View flex={1} minHeight={Platform.OS === 'web' ? '120vh' : 'auto'}>
            <ScrollView horizontal={false} contentContainerStyle={{ paddingBottom: 600 }}>
                <Flex paddingX={4}>
                    <Text
                        fontFamily='semiBold'
                        paddingTop={8}>
                        {strings?.["Where do you want to have the service"]}
                    </Text>
                    <View paddingTop={4}>
                        {
                            locations?.map(r => {
                                return (
                                    <Pressable
                                        w={'100%'}
                                        onPress={() => {
                                            setState(r.id);
                                            dispatch(SetUserDefaultLocation({ ...r, created: moment().valueOf() }));
                                        }}
                                        flexDirection={'row'}
                                        borderRadius={8}
                                        marginBottom={4}
                                        backgroundColor='#fff'
                                        padding={4}
                                        alignItems='center'
                                        justifyContent={'space-between'}
                                        key={r.id}>
                                        <View w={'80%'}>
                                            <Text fontFamily='semiBold'>
                                                {r.label}
                                            </Text>
                                            <Text fontFamily='medium' paddingTop={1}>
                                                {FormatAddress(r)}
                                            </Text>
                                        </View>
                                        <Image
                                            svg
                                            fill='#32A2DC'
                                            uri={r.id === state ? FilledDot : EmptyDot}
                                            width={15}
                                            height={15}
                                        />

                                    </Pressable>
                                )
                            })
                        }

                    </View>
                    <View paddingTop={8}>
                        <Pressable onPress={() => {
                            Navigation.navigate('AddNewLocation')
                        }}>
                            <Text style={{ color: '#32A2DC' }} fontFamily='semiBold'>
                                {strings?.["+ Add new address"]}
                            </Text>
                        </Pressable>
                    </View>
                </Flex>

            </ScrollView>
            {
                !hideActions ? <OrderSummarySheet
                    bottom={88}
                    fixed={true}
                    disabled={!state}
                    onPress={() => {
                        if (state) {
                            batch(() => {
                                let selectedLocation = locations.find(r => r.id === state);
                                dispatch(SetOrderLocation(selectedLocation));
                                dispatch(SetUserDefaultLocation({ ...selectedLocation, created: moment().valueOf() }));
                            })
                            Navigation.navigate(service?.id === 'pharmacy' ? 'Cart' : 'TimmingScreen')
                            return
                        }
                        toast.show({
                            title: strings?.['Incomplete data'],
                            status: "error",
                            placement: "top",
                            description: strings?.["Please select your address"]
                        })
                    }
                    } />
                    :
                    <View />
            }

        </View>
    );
};

export default Address;