import React, { useEffect } from 'react';

import { createStackNavigator } from "@react-navigation/stack";
import Main from './index';
import Header from '../../shared/Header';
import BookingHistory from './com/BookingHistory';
import Language from './com/Language';
import ContactUs from './com/ContactUs';
import Locations from './com/Locations';
import PaymentMethods from './com/PaymentMethods';
import { Platform } from 'react-native';
import Wallet from './com/Wallet';
import ContactInfo from './com/ContactInfo.web';
import { useSelector } from 'react-redux';
import { strings } from '../../../translations/index';
// import ContactInfo from './com/ContactInfo';

const MenuStack = createStackNavigator();
const MenuNavigator = () => {
    //don't remove this!!!!!
    const { locale } = useSelector(state => state.locale);
    return (
        <MenuStack.Navigator
            initialRouteName="main-menu"
            screenOptions={{
                headerShown: true,
                cardStyle: { backgroundColor: '#F5F7F8' }
            }}>
            <MenuStack.Screen name="main-menu" component={Main} options={{
                header: () => Platform.OS !== 'web' ? null : <Header title={strings?.['Profile']} />,
            }} />
            <MenuStack.Screen name="bookings-history" component={BookingHistory} options={{
                header: () => <Header title={strings?.['Order History']} />,
            }} />
            <MenuStack.Screen name="contact-info" component={ContactInfo} options={{
                header: () => <Header title={strings?.['Personal info']} />,
            }} />
            {/* <MenuStack.Screen name="contact-info" component={ContactInfo} options={{
                header: () => <Header title='Personal info' />,
            }} /> */}
            <MenuStack.Screen name="language" component={Language} options={{
                header: () => <Header title={strings?.['Language']} />,
            }} />
            <MenuStack.Screen name="chat" component={ContactUs} options={{
                header: () => <Header title={strings?.['Contact Us']} />,
            }} />
            <MenuStack.Screen name="wallet" component={Wallet} options={{
                header: () => null,
            }} />
            <MenuStack.Screen name="locations" component={Locations} options={{
                header: () => <Header title={strings?.['Locations']} />,
            }} />
            <MenuStack.Screen name="paymentMethods" component={PaymentMethods} options={{
                header: () => <Header title={strings?.['Payment Methods']} />,
            }} />

        </MenuStack.Navigator>
    )
}

export default MenuNavigator;
