import Axios from 'axios';
import { auth } from '../constants/firebase/config'
import { Localize } from './helpers';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
export async function onGoogleButtonPress() {
  const provider = new auth.GoogleAuthProvider();
  provider.setCustomParameters({
    'display': 'popup'
  });
  return auth().signInWithPopup(provider)
}
export async function onFacebookButtonPress() {
  const provider = new auth.FacebookAuthProvider();
  provider.setCustomParameters({
    'display': 'popup'
  });
  return auth().signInWithPopup(provider)

}
export async function onAppleButtonPress() {
}
export function logout() {
  auth().signOut()

}
export async function BuildShareLink(params) {
  let { sharedPath, item } = params;
  let hostname = window.location.hostname

  let encoded = encodeURI(`https://${hostname}/${sharedPath}?sharedPath=${sharedPath}`);
  switch (sharedPath) {
    case 'Services':
      encoded = encodeURI(`https://${hostname}/services?sharedPath=${sharedPath}&serviceID=${item?.id}`);
      break
    case 'service_page':
      encoded = encodeURI(`https://${hostname}/service_page?sharedPath=${sharedPath}&id=${item?.id}&type=${item?.service_id === 'pharmacy' ? 'medicine' : 'variation'}`);
      break
    default: break;
  }

  let link = (`https://metadoc.page.link/?link=${encoded}&apn=com.metadocapp&isi=6443656840&ibi=org.metadocapp&efr=1&utm_medium=referral&st=${Localize(item.name)} | Metadoc&sd=${Localize(item.details)}&si=${item?.picture?.[0] || item?.image || ''}`)
  let results = await Axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCkssbTaiK2IJ7LPEHuUvWZe_6B3mzbgqU', {
    'longDynamicLink': link,
    "suffix": {
      "option": "SHORT"
    },

  })
  console.log("results?.data?.shortLink", results?.data?.shortLink);
  return results?.data?.shortLink

}
export function appsFlyerLogEvent(eventkey, eventParams) {
  // appsFlyer.logEvent(eventkey, eventParams)
}

export function RateOnAppstore() {

}


export function InitializeSentry() {
  Sentry.init({
    ignoreErrors: [/(localhost|127\.0\.0\.1)/i],
    dsn: "https://e95d7f0e148f4b3a8ea2f72078cc59e6@o1430237.ingest.sentry.io/4503891099582464",
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export const WrapApp = (app) => {
  return app
}