import { useNavigation } from '@react-navigation/native';
import { ScrollView, Spinner, View } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { Platform, Animated } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import CardList from '../../shared/CardList';
import OrderSummarySheet from '../../shared/OrderSummarySheet';
import Text from '../../shared/TextLabel';
import ServicesHeader from './com/ServicesHeader';
import { analytics } from "../../../constants/firebase/config";
import { SetRequiredLocation } from '../../../reducers/settings';
import { appsFlyerLogEvent } from '../../../helpers/index';
// import { SetToast } from '../../../reducers/alertsReducer';

const IMAGE_SCALE_MAX = 3;
const LABEL_HEADER_MARGIN = 10;

const Index = ({ route }) => {
    const { variations, services, webView, brand } = useSelector(state => state.settings);
    const user = useSelector(state => state.user);
    const { items } = useSelector(state => state.order);
    const { serviceID } = route?.params || {};
    const [activeService, setActiveService] = useState(services?.find(r => r.id === serviceID))
    const [activeVariations, setVariations] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (services) {
            setActiveService(services?.find(r => r.id === serviceID))
        }
    }, [services])

    const CurrentUserRef = useRef();
    CurrentUserRef.current = user
    const dispatch = useDispatch();
    useEffect(() => {
        if (Platform.OS === 'web') {
            window.scrollTo(0, 0)
        }
    }, [])
    useEffect(() => {
        let activeVariations = variations?.filter(r => r.service_id === serviceID)
        setVariations(activeVariations?.sort((a, b) => a.index - b.index))
        setLoading(false)
    }, [serviceID, variations])


    useEffect(() => {
        let enabled = true;
        if (Platform.OS === 'web') {
            enabled = !document?.location?.search?.includes('fromMeta') && !document?.location?.search?.includes('campaign')
        }
        let timeout = setTimeout(() => {
            if (!user?.defaultLocation && enabled && brand?.customLocations) {
                dispatch(SetRequiredLocation({
                    open: true,
                }))
            }
        }, 1000);
        return () => {
            clearTimeout(timeout)
        }
    }, [user?.defaultLocation])

    // useEffect(() => {
    //     dispatch(SetToast({
    //         id: Date.now(),
    //         title: "Discount available",
    //         variant: "top-accent",
    //         description: "This is to inform you that your network connectivity is restored",
    //         isClosable: true,
    //     }))
    // }, [])
    const navigation = useNavigation()

    useEffect(() => {
        async function fetchData() {
            appsFlyerLogEvent('view_item', {
                af_id: activeService?.id,
                af_item: activeService?.name?.en,
                af_description: activeService?.details?.en,
                af_type: 'service',
                af_platform: Platform.OS
            })
            await analytics().logEvent('view_item', {
                id: activeService?.id,
                item: activeService?.name?.en,
                description: activeService?.details?.en,
                type: 'service',
                platform: Platform.OS

            })
        }
        fetchData();
    }, [activeService]);
    const pan = useRef(new Animated.ValueXY()).current;
    return (
        <View flex={1} height={webView ? '100vh' : 'auto'} minHeight={Platform.OS === 'web' ? '100vh' : '100%'} justifyContent='space-between' >
            <ScrollView
                alwaysBounceVertical={false}
                onScroll={Animated.event(
                    [{ nativeEvent: { contentOffset: { y: pan.y } } }],
                    {
                        useNativeDriver: false,
                    },
                )}
                scrollEventThrottle={1}
                contentInsetAdjustmentBehavior="never"
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                    border: 'none',
                    paddingBottom: 200
                }}
            >
                <ServicesHeader
                    data={activeService}
                    animStyle={{
                        transform: [
                            {
                                translateY: pan.y.interpolate({
                                    inputRange: [-1000, 0],
                                    outputRange: [-500, 0],
                                    extrapolate: 'clamp',
                                }),
                            },
                            {
                                scale: pan.y.interpolate({
                                    inputRange: [-400, 0],
                                    outputRange: [IMAGE_SCALE_MAX, 1],
                                    extrapolate: 'clamp',
                                }),
                            },
                        ],
                    }}
                    revertStyle={{
                        opacity: pan.y.interpolate({
                            inputRange: [-20, 0],
                            outputRange: [0, 1],
                            extrapolate: 'clamp',
                        }),
                    }}
                />
                <Animated.View style={{
                    padding: 16,
                    borderTopRightRadius: 16,
                    borderTopLeftRadius: 16,
                    flex: 1,
                    top: -12,
                    backgroundColor: '#F5F7F8',
                    transform: [
                        {
                            translateY: pan.y.interpolate({
                                inputRange: [-1000, 0],
                                outputRange: [LABEL_HEADER_MARGIN * IMAGE_SCALE_MAX, 0],
                                extrapolate: 'clamp',
                            }),
                        },
                    ],
                }}>
                    {
                        activeVariations?.length ?
                            activeVariations?.map((rec, i) => <View key={i} paddingBottom={4}>
                                <CardList data={rec} />
                            </View>)
                            :
                            <View flex={1} paddingTop={'60%'} justifyContent='center' alignItems={'center'}>
                                {
                                    loading ?
                                        <Spinner size={'lg'} />
                                        : <Text fontFamily='medium'>
                                            No services available at selected location
                                        </Text>

                                }


                            </View>
                    }
                </Animated.View>
            </ScrollView>
            <OrderSummarySheet disabled={Object.values(items)?.length < 1} onPress={() => {
                if (!user?.defaultLocation) {
                    dispatch(SetRequiredLocation({
                        open: true,
                    }))
                    return

                }

                navigation.navigate('Booking')

            }} />
        </View >

    );
};

export default Index;