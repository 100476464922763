import { Button, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { calcTotalCost, Localize, shadow } from '../../helpers/helpers';
import Text from './TextLabel';
import { useDispatch, useSelector } from 'react-redux';
import Modal from './Modal/Modal';
import PaymentBtn from '../Payment/PaymentBtn'
import { Platform } from 'react-native';
import Counter from './Counter';
import { UpdateOrderItems } from '../../reducers/order';
import { strings } from '../../translations/index';
import PopoverIcon from './Popover';
import { isMobile } from 'react-device-detect';

const OrderSummarySheet = ({ onPress, disabled, loading, btnTitle = 'Continue', btnProps, fixed = true, walletActive, orderData, walletPayNow, selectCount, hideExtras, bottom = 0 }) => {
    const { items, service, discount } = useSelector(state => state.order);
    const { wallet } = useSelector(state => state.user);
    const { brandLoyalty } = useSelector(state => state.settings);

    const [show, toggle] = useState(false);
    const dispatch = useDispatch();
    const [state, setState] = useState([]);
    const [recipt, setReceipt] = useState({});
    useEffect(() => {
        let data = [];
        if (orderData) {
            data = Object.keys(orderData?.items).map(rec => {
                let item = orderData?.items[rec];
                let post = {
                    ...item, id: rec
                }
                return post
            })

            setState(data)
            setReceipt(orderData?.receipt)
            return
        }
        if (items) {
            data = Object.keys(items).map(rec => {
                let item = items[rec];
                let post = {
                    ...item, id: rec
                }
                return post
            })
        }
        setState(data)
        setReceipt(dispatch(calcTotalCost(items, { ...wallet, ...brandLoyalty }, discount, hideExtras)))
    }, [items, orderData, discount, wallet, hideExtras])
    return (
        <View position={Platform.OS === 'web' ? 'sticky' : 'static'} bottom={0} >
            {
                show &&
                <Modal fixed={false} style={{ width: '100%', marginLeft: 0 }} dismiss={() => toggle(false)}>
                    <View w={'100%'} backgroundColor='#fff' position='absolute' zIndex={999} bottom={isMobile ? 0 : bottom}  >
                        <View alignItems='center' padding={4} >
                            <View w={'40%'} borderRadius={8} h={1} backgroundColor={'#EBF2F9'} />
                        </View>
                        <View margin={6} padding={4} borderWidth={1} borderRadius={8} borderColor={'#E2E9F0'} >
                            {
                                state?.map(rec => {
                                    return (<View key={rec.id}>
                                        <View flexDirection={'row'} justifyContent='space-between'>
                                            <Text
                                                fontFamily='semiBold'
                                                w={'70%'}
                                                fontWeight={400}
                                                style={{
                                                    fontSize: 12,
                                                }} >
                                                {Localize(rec?.name)} X{rec.count}
                                            </Text>
                                            <Text fontFamily='semiBold'
                                                style={{
                                                    fontSize: 12,
                                                }} > {rec.price?.total} {strings.AED}</Text>
                                        </View>
                                        <Text w={'70%'} fontWeight={400} style={{ fontSize: 10 }} >
                                            {rec.desc}
                                        </Text>
                                    </View>)
                                })
                            }
                            <View flexDirection={'row'} paddingTop={4} justifyContent='space-between'>
                                <Text fontSize={12}>
                                    {strings.Subtotal}
                                </Text>
                                <Text fontSize={12}>
                                    {recipt?.sub} {strings.AED}
                                </Text>
                            </View>
                            {
                                recipt?.discount && <View flexDirection={'row'} paddingTop={3} justifyContent='space-between'>
                                    <Text fontSize={12} style={{ color: '#32A2DC' }}>
                                        {recipt?.type === 'code' ? strings?.DiscountCode : strings.Discount}
                                        {
                                            recipt?.type === 'code' ?
                                                <Text fontSize={12} style={{ color: '#32A2DC' }}> ({recipt?.code})</Text>
                                                :
                                                <View />
                                        }
                                    </Text>
                                    <Text fontSize={12} style={{ color: '#32A2DC' }}>
                                        - {recipt?.discount} {strings.AED}
                                    </Text>
                                </View>

                            }
                            {
                                recipt?.serviceFees && <View flexDirection={'row'} paddingTop={3} justifyContent='space-between' alignItems={'center'}>
                                    <View flexDirection={'row'} alignItems='center'>
                                        <Text fontSize={12}>
                                            {Localize(recipt?.serviceFees?.title)}
                                        </Text>
                                        <PopoverIcon data={recipt?.serviceFees} />

                                    </View>

                                    <Text fontSize={12} >
                                        {recipt?.serviceFees?.value} {strings.AED}
                                    </Text>
                                </View>
                            }
                            {/* {
                                recipt?.tax ? <View flexDirection={'row'} paddingTop={3} justifyContent='space-between'>
                                    <Text fontSize={12}>
                                        {strings.VAT}
                                    </Text>
                                    <Text fontSize={12} >
                                        {recipt?.tax} {strings.AED}
                                    </Text>
                                </View>
                                    :
                                    <View />
                            } */}

                            <View flexDirection={'row'} paddingTop={3} justifyContent='space-between'>
                                <Text fontFamily='bold'
                                    style={{
                                        fontSize: 12,
                                    }}>
                                    {strings.Total}
                                </Text>
                                <Text fontFamily='bold'
                                    style={{
                                        fontSize: 12,
                                    }}>
                                    {recipt?.total} {strings.AED}
                                </Text>
                            </View>
                        </View>

                    </View>
                </Modal>
            }
            {
                !disabled ? <View
                    position={'absolute'}
                    bottom={0}
                    left={0}
                    right={0}
                    // marginBottom={0}
                    // marginTop='auto'
                    // w={'100%'}
                    backgroundColor='#fff'
                    shadow={shadow({ color: '#000000', radius: 8, offsetWidth: 0, offsetHeight: 0, opacity: 0.05 })}
                    padding={4}
                    justifyContent='space-between'
                    borderTopRadius={8}>
                    <View flexDirection={'row'} justifyContent='space-between' alignItems={'center'}>
                        <View>
                            <Text style={{ color: '#C6C6C6' }}>
                                {strings.Total} {recipt?.discount ? <Text style={{ fontSize: 10, textDecorationLine: 'line-through' }}>
                                    {strings.AED} {(Number(recipt?.total || 0) + Number(recipt?.discount || 0)).toFixed(2)}
                                </Text> : <></>}
                            </Text>
                            <Text fontFamily='bold' fontSize={16}>
                                {strings.AED} {recipt?.total}
                            </Text>
                        </View>
                        <Button variant={'unstyled'} onPress={() => toggle(true)}>
                            <Text fontSize={12} style={{ color: '#C6C6C6' }}>
                                {strings["View items"]}
                            </Text>

                        </Button>
                    </View>
                    <View paddingTop={4}>
                        {
                            walletActive && (orderData || recipt) ?
                                <PaymentBtn
                                    walletPayNow={walletPayNow}
                                    data={orderData}
                                    receipt={{ ...recipt, service: service?.name }} />
                                :

                                <View {...btnProps} marginBottom={2} flexDirection={'row'} justifyContent={'space-between'} alignItems='center'>
                                    <Button
                                        flex={selectCount ? 0.5 : 1}
                                        //
                                        shadow={shadow({ color: '#00000012', radius: 10, offsetWidth: 0, offsetHeight: 0 })}
                                        isLoading={loading}
                                        _loading={{
                                            backgroundColor: '#20A2DF',
                                        }}
                                        _spinner={{
                                            color: '#fff'
                                        }}
                                        _disabled={{
                                            backgroundColor: '#20A2DF',
                                            color: '#ffffff',
                                            _text: {
                                                color: '#ffffff',
                                            },
                                            opacity: 0.3
                                        }}
                                        height={50}
                                        isDisabled={disabled}
                                        isLoadingText=' '

                                        onPress={() => {
                                            if (!disabled) {
                                                onPress()
                                            }
                                        }}
                                        borderRadius={8}
                                    >
                                        <Text style={{ color: '#ffffff' }} fontFamily='medium'>
                                            {strings[btnTitle]}
                                        </Text>
                                    </Button>
                                    {
                                        selectCount &&
                                        <View flex={0.32} paddingX={2}>
                                            <Counter
                                                direction='row-reverse'
                                                count={items?.[selectCount?.id]?.count}
                                                minCount={selectCount?.min_count}
                                                maxCount={selectCount?.max_count}
                                                data={selectCount}
                                                updateCount={
                                                    (count) => {
                                                        let orderList = JSON.parse(JSON.stringify(items))
                                                        if (count > 0) {
                                                            dispatch(UpdateOrderItems({ ...orderList, [selectCount?.id]: { count: count, price: selectCount?.pricing, name: selectCount?.name, service_id: selectCount.service_id } }))
                                                        } else {
                                                            delete orderList?.[selectCount?.id]
                                                            dispatch(UpdateOrderItems(orderList))
                                                        }
                                                    }
                                                } />

                                        </View>

                                    }
                                </View>
                        }
                    </View>
                </View> : <></>}
        </View>
    );
};

export default OrderSummarySheet;